import { useSubmitButton, useTextInput } from "morfhp";
import { useState } from "react";
import { TodoBoard } from "../../../../domain/model/todo-board";
import { validateTodoBoardTitle } from "../../../../domain/validators/todo-board-validators";
import { ErrorMessage } from "../../../atoms/error/error-message";
import { SaveButton } from "../../../molecules/buttons/save-button";
import { TextInputGroup } from "../../../molecules/input/text-input-group";

interface TodoBoardFormProps {
  board?: TodoBoard,
  onSubmit: (todoBoard: TodoBoard) => Promise<void>
}

export const TodoBoardForm: React.FC<TodoBoardFormProps> = ({
  board,
  onSubmit
}) => {

  const [error, setError] = useState("");

  const titleInput = useTextInput({
    Component: TextInputGroup,
    isRequired: true,
    label: "Title",
    validator: validateTodoBoardTitle,
    defaultValue: board?.title || "",
    emptyValueMessage: "Please enter a title",
    validateInitially: !!board
  });

  const submitButton = useSubmitButton({
    inputs: [titleInput],
    Component: SaveButton,
    onSubmit: innerOnSubmit,
  });

  async function innerOnSubmit() {
    try {

      const title = titleInput.formValue.value!;

      const newBoard = !!board
        ? new TodoBoard(board.id, title, board.creationTimestamp, board.columns, board.isHidden)
        : TodoBoard.create(title);

      await onSubmit(newBoard);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <>
    {!!error && <ErrorMessage message={error} />}
    {titleInput.jsx}
    {submitButton.jsx}
  </>
}
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react";
import { SuccessButton } from "../../../molecules/buttons/success-button";

interface StartItemTrackingButtonProps {
    onClick: () => Promise<void>
}

export const StartItemTrackingButton: React.FC<StartItemTrackingButtonProps> = ({
    onClick
}) => {

    const [isLoading, setIsLoading] = useState(false);

    const innerOnClick = async () => {
        setIsLoading(true);
        try {
            await onClick();
        } catch (error) {
            alert((error as Error).message);
        }
        setIsLoading(false);
    }

    return <SuccessButton
        canSubmit={!isLoading}
        isLoading={isLoading}
        onSubmit={innerOnClick}
        text="Start tracking"
        collapsible={true}
        icon={faPlay}
    />
}
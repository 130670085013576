import { faEye, faEyeSlash, faPencil, faPlus, faTrash, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { TodoBoard } from "../../../domain/model/todo-board"
import { TodoColumn } from "../../../domain/model/todo-column"
import { useModal } from "../../../hooks/useModal"
import { TodoManager } from "../../../hooks/useTodoManager"
import { Row } from "../../atoms/row/row"
import { ConfirmationDialog } from "../../organisms/dialogs/confirmation-dialog"
import { TodoBoardForm } from "../../organisms/todo/board/todo-board-form"
import { TodoColumnForm } from "../../organisms/todo/column/todo-column-form"
import { DangerButton } from "../buttons/danger-button"
import { LightButton } from "../buttons/light-button"
import { SuccessButton } from "../buttons/success-button"
import { WarningButton } from "../buttons/warning-button"

interface TodoBoardButtonsProps {
  board: TodoBoard,
  todoManager: TodoManager
}

export const TodoBoardButtons: React.FC<TodoBoardButtonsProps> = ({
  board,
  todoManager
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();

  const onCreateColumnSubmit = async (column: TodoColumn) => {
    await todoManager.addTodoColumn(column, board);
    modal.close();
  }

  const onCreateColumnClick = () => {
    modal.show({
      title: "Create column",
      children: <TodoColumnForm onSubmit={onCreateColumnSubmit} />
    });
  };

  const onEditBoardSubmit = async (todoBoard: TodoBoard) => {
    await todoManager.updateTodoBoard(todoBoard);
    modal.close();
  }

  const onEditBoardClick = () => {
    modal.show({
      title: "Edit board",
      children: <TodoBoardForm board={board} onSubmit={onEditBoardSubmit} />
    })
  }

  const onDeleteBoardSubmit = async () => {
    await todoManager.removeTodoBoard(board);
    modal.close();
  }

  const onDeleteBoardClick = () => {
    modal.show({
      title: "Remove board?",
      children: <ConfirmationDialog
        onCancel={modal.close}
        onConfirm={onDeleteBoardSubmit}
        text={[
          "Are you sure you want to remove this board?",
          "All columns and to do items on this board will be removed",
          "This action can not be undone."
        ]}
      />
    })
  }

  const onHideBoardSubmit = async () => {
    setIsLoading(true);
    const updatedBoard = new TodoBoard(board.id, board.title, board.creationTimestamp, board.columns, !board.isHidden);
    await todoManager.updateTodoBoard(updatedBoard);
    setIsLoading(false);
  }
  return <Row margin="none">
    <Row margin="none" className="todo-board-buttons-hint">
        <FontAwesomeIcon icon={faEllipsisVertical} />
    </Row>
    <Row margin="none" className="todo-board-buttons">
      <LightButton
        collapsible={true}
        icon={board.isHidden ? faEye : faEyeSlash}
        canSubmit={true}
        isLoading={isLoading}
        onSubmit={onHideBoardSubmit}
        text={board.isHidden ? "Show" : "Hide"}
      />
      <SuccessButton
        collapsible={true}
        icon={faPlus}
        canSubmit={true}
        onSubmit={onCreateColumnClick}
        isLoading={false}
        text="Add column"
      />
      <WarningButton
        collapsible={true}
        icon={faPencil}
        canSubmit={true}
        onSubmit={onEditBoardClick}
        isLoading={false}
        text="Edit"
      />
      <DangerButton
        collapsible={true}
        icon={faTrash}
        canSubmit={true}
        isLoading={false}
        onSubmit={onDeleteBoardClick}
        text="Delete"
      />
    </Row>
  </Row>
}
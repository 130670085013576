import { faUser } from "@fortawesome/free-solid-svg-icons"
import { SuccessButton } from "./success-button"

interface LoginButtonProps {
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean,
  isLoading: boolean,
  text?: string
}

export const LoginButton: React.FC<LoginButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text = "Log in"
}) => {
  return <SuccessButton
    onSubmit={onSubmit}
    icon={faUser}
    text={text}
    canSubmit={canSubmit}
    isLoading={isLoading}
  />
}
import { ReactNode, useEffect, useState } from "react"
import { AuthenticationRule, useAuthentication } from "../../../firebase/useAuthentication";
import { CookiePopup } from "../cookies/CookiePopup";
import { NavigationBar } from "../navigation-bar/navigation-bar"
import { DropdownContainer } from "./dropdown-container";
import { ModalContainer } from "./modal-container";

import "./page-container.css";

interface PageContainerProps {
    children?: ReactNode,
    justifyContent?: "center" | "flex-start" | "flex-end",
    authentication?: AuthenticationRule,
    title: string
}

export const PageContainer: React.FC<PageContainerProps> = ({
    children,
    justifyContent,
    authentication = AuthenticationRule.MustBeAuthenticated,
    title
}) => {

    const [cookiesAccepted, setCookiesAccepted] = useState(true);
    useAuthentication(authentication);

    useEffect(() => {
        const areCookiesAlreadyAccepted = localStorage.getItem("cookiesAcceptedFlag");
        if (!areCookiesAlreadyAccepted) {
            setCookiesAccepted(false);
        }
    }, []);

    useEffect(() => {
        document.title = `Firetrack - ${title}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const acceptCookies = async () => {
        localStorage.setItem("cookiesAcceptedFlag", "true");
        setCookiesAccepted(true);
    }

    const className = justifyContent
        ? `main-${justifyContent} appear-gradually`
        : "main appear-gradually"

    return <>
        {!cookiesAccepted && <CookiePopup
            acceptCookies={acceptCookies}
        />}
        <ModalContainer />
        <DropdownContainer />
        <header>
            <NavigationBar />
        </header>
        <main className={className}>
            {children}
        </main>
    </>
}
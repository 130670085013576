import { useLogout } from "../../firebase/useAuthentication"
import { Logger } from "../../logging/logger"
import { Row } from "../atoms/row/row"
import { Spinner } from "../atoms/spinner/spinner"
import { PageContainer } from "../organisms/page-container/page-container"

export const LogoutPage: React.FC = () => {

  useLogout();

  Logger.debug("Logging out...");

  return <PageContainer
    justifyContent="center"
    title="Logging out..."
  >
    <Row justifyContent="center">
      <Spinner />
    </Row>
  </PageContainer>
}
import { useSubmitButton, useTextInput } from "morfhp";
import { useState } from "react";
import { TodoColumn } from "../../../../domain/model/todo-column";
import { validateTodoColumnTitle } from "../../../../domain/validators/todo-column-validators";
import { ErrorMessage } from "../../../atoms/error/error-message";
import { SaveButton } from "../../../molecules/buttons/save-button";
import { TextInputGroup } from "../../../molecules/input/text-input-group";

interface TodoColumnFormProps {
  column?: TodoColumn,
  onSubmit: (todoColumn: TodoColumn) => Promise<void>
}

export const TodoColumnForm: React.FC<TodoColumnFormProps> = ({
  column,
  onSubmit
}) => {

  const [error, setError] = useState("");

  const titleInput = useTextInput({
    isRequired: true,
    Component: TextInputGroup,
    label: "Title",
    validator: validateTodoColumnTitle,
    defaultValue: column?.title || "",
    emptyValueMessage: "Please enter a title",
  });

  const submitButton = useSubmitButton({
    Component: SaveButton,
    onSubmit: innerOnSubmit,
    inputs: [titleInput]
  });

  async function innerOnSubmit() {
    try {

      const title = titleInput.formValue.value!;

      const newColumn = !!column
        ? new TodoColumn(column.id, title, column.creationTimestamp, column.items, column.isHidden)
        : TodoColumn.create(title);

      await onSubmit(newColumn);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <>
    {!!error && <ErrorMessage message={error} />}
    {titleInput.jsx}
    {submitButton.jsx}
  </>
}
import { useEffect } from "react";
import { TimeRecord } from "../../../domain/model/time-record";
import { useActiveTimeTracking } from "../../../hooks/useActiveTimeTracking"
import { ErrorMessage } from "../../atoms/error/error-message";
import { Row } from "../../atoms/row/row"
import { Spinner } from "../../atoms/spinner/spinner";
import { CurrentRecordCard } from "../../molecules/time-tracking/current-record-card";
import { StartTrackingForm } from "../../molecules/time-tracking/start-tracking-form";

interface ActiveTimeTrackingProps {
  onUpdate: () => void,
  recordToTrack: TimeRecord | undefined,
  clearRecordToTrack: () => void
}

export const ActiveTimeTracking: React.FC<ActiveTimeTrackingProps> = ({
  onUpdate,
  recordToTrack,
  clearRecordToTrack
}) => {

  const timeTracking = useActiveTimeTracking(onUpdate);

  useEffect(() => {
    if (recordToTrack) {
      timeTracking.start(recordToTrack.description, recordToTrack.projectId);
      clearRecordToTrack();
    }
  }, [recordToTrack, timeTracking, clearRecordToTrack]);

  return <>
    {!!timeTracking && !!timeTracking.error && <Row justifyContent="left"><ErrorMessage message={timeTracking.error} /></Row>}
    <Row justifyContent="left">
      {
        timeTracking.isLoading
          ? <Spinner />
          : timeTracking.currentTimeRecord
            ? <CurrentRecordCard
              isLoading={timeTracking.isLoading}
              record={timeTracking.currentTimeRecord}
              onUpdate={timeTracking.updateCurrentRecord}
              onStop={timeTracking.stop}
              onStartNew={() => timeTracking.start("")}
            />
            : <StartTrackingForm onStart={timeTracking.start} />
      }
    </Row>

  </>
}
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import "./timestamp-input.css";

interface TimestampInputProps {
  id: string,
  isRequired?: boolean,
  onValueChange: (input: DateTime) => void,
  isError: boolean,
  secureTextEntry?: boolean,
  value?: DateTime
}

export const TimestampInput: React.FC<TimestampInputProps> = ({
  id,
  isRequired,
  onValueChange,
  isError,
  value
}) => {

  const [date, setDate] = useState(value || DateTime.now());
  const [hour, setHour] = useState(value?.hour || 0);
  const [minutes, setMinutes] = useState(value?.minute || 0);

  useEffect(() => {
    try {
      const valueChanged = value?.startOf('day').toMillis() !== date.startOf('day').toMillis() ||
        value?.hour !== hour ||
        value?.minute !== minutes;

      const valueValid = hour >= 0 && hour <= 23 && minutes >= 0 && minutes <= 59;

      if (valueChanged && valueValid) {
        onValueChange(date.set({ hour, minute: minutes }));
      }
    } catch (error) {
      console.error(`Something went wrong while trying to parse date: ${(error as Error).message}`)
    }
  }, [onValueChange, date, hour, minutes, value]);

  const className = isError
    ? "text-input text-input-error"
    : "text-input"

  const hourValue = `${hour}`.length <= 1
    ? `0${hour}`
    : hour;

  const minuteValue = `${minutes}`.length <= 1
    ? `0${minutes}`
    : minutes

  return <div className={className}>
    <input
      id={id}
      onChange={(e) => setDate(DateTime.fromISO(e.target.value))}
      required={isRequired}
      type="date"
      value={date.toFormat("yyyy-MM-dd")}
      className="timestamp-input-date"
    />
    <input
      onChange={(e) => setHour(!!e.target.value ? parseInt(e.target.value, 10) : 0)}
      required={isRequired}
      type="number"
      min={0}
      max={23}
      value={hourValue}
      className="timestamp-input-hour"
    />
    <input
      onChange={(e) => setMinutes(!!e.target.value ? parseInt(e.target.value, 10) : 0)}
      required={isRequired}
      type="number"
      min={0}
      max={59}
      value={minuteValue}
      className="timestamp-input-minute"
    />
  </div>
}
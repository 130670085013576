import { SuccessButton } from "./success-button"
import { faPlus, IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface AddButtonProps {
  onClick: () => void | Promise<void>,
  canSubmit?: boolean,
  isLoading?: boolean,
  text?: string,
  icon?: IconDefinition,
  collapsible?: boolean
}

export const AddButton: React.FC<AddButtonProps> = ({
  onClick,
  canSubmit = true,
  isLoading = false,
  text = "Add",
  icon = faPlus,
  collapsible = false
}) => {

  return <SuccessButton 
    canSubmit={canSubmit}
    isLoading={isLoading}
    onSubmit={onClick}
    text={text}
    icon={icon}
    collapsible={collapsible}
  />
}
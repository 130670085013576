import { query, getDocs, where } from "firebase/firestore";
import { CollectionReference } from "firebase/firestore";
import { DateTime } from "luxon";
import { toDateInteger } from "../../helpers/timestamp-helpers";
import { TimeRecord } from "./time-record";
import { TimeRecords } from "./time-records";

export class TimeRecordsByDay implements TimeRecords {

  private constructor(
    readonly records: TimeRecord[],
    private readonly dateLimit: DateTime,
    private readonly days: number,
    private readonly collection: CollectionReference<TimeRecord>
  ) {
  }

  private static async getResults(
    dateBefore: DateTime,
    dateAfter: DateTime,
    collection: CollectionReference<TimeRecord>
  ): Promise<TimeRecord[]> {
    const whereBefore = where("startInteger", "<", toDateInteger(dateBefore));
    const whereAfter = where("startInteger", ">=", toDateInteger(dateAfter));
    const q = query(collection, whereBefore, whereAfter)
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data());
  }

  private static async getNextDate(
    dateBefore: DateTime,
    collection: CollectionReference<TimeRecord>
  ): Promise<DateTime | undefined> {
    const whereBefore = where("startInteger", "<", toDateInteger(dateBefore));
    const q = query(collection, whereBefore);
    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => doc.data());
    if (docs.length !== 0) {
      let youngestDoc = docs[0];
      for (const doc of docs) {
        if (doc.start > youngestDoc.start) {
          youngestDoc = doc;
        }
      }
      return youngestDoc.start.endOf("day");
    }
    return undefined;
  }

  async getNext(): Promise<TimeRecords> {
    const nextDate = this.dateLimit.minus({ days: this.days });
    const results = await TimeRecordsByDay.getResults(this.dateLimit, nextDate, this.collection)
    if (results.length === 0) {
      const nextEndDate = await TimeRecordsByDay.getNextDate(nextDate, this.collection);
      if (nextEndDate) {
        const nextStartDate = nextEndDate.minus({days: this.days});
        const nextResults = await TimeRecordsByDay.getResults(nextEndDate, nextStartDate, this.collection);
        return new TimeRecordsByDay(
          [...this.records, ...nextResults],
          nextStartDate,
          this.days,
          this.collection
        );
      }
    }
    return new TimeRecordsByDay(
      [...this.records, ...results],
      nextDate,
      this.days,
      this.collection
    );
  }

  static async lastDays(collection: CollectionReference<TimeRecord>, days = 7): Promise<TimeRecordsByDay> {
    const dateBefore = DateTime.now().plus({ days: 1});
    const dateAfter = DateTime.now().minus({ days });
    const results = await this.getResults(dateBefore, dateAfter, collection);
    return new TimeRecordsByDay(results, dateAfter, days, collection);
  }
}
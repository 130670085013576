import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { Button } from "../../atoms/buttons/button"

interface SuccessButtonProps {
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean,
  isLoading: boolean,
  text: string,
  loadingText?: string,
  icon?: IconDefinition,
  collapsible?: boolean
}

export const SuccessButton: React.FC<SuccessButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text,
  loadingText,
  icon,
  collapsible = false
}) => {
  return <Button
    canSubmit={canSubmit}
    isLoading={isLoading}
    onSubmit={onSubmit}
    className={"success-button"}
    loadingText={loadingText}
    text={text}
    icon={icon}
    collapsible={collapsible}
  />
}
import { MutableRefObject, ReactNode, useState } from "react";
import { singletonHook } from "react-singleton-hook";

interface ShowDropdownProps {
  children: ReactNode,
  parent: MutableRefObject<unknown>
}

const useDropdownImplementation = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [children, setChildren] = useState<ReactNode | undefined>(undefined);
  const [parent, setParent] = useState<MutableRefObject<unknown>>();

  const close = () => {
    setIsVisible(false);
    setChildren(undefined);
  }

  const show = ({
    children,
    parent
  }: ShowDropdownProps) => {
    setChildren(children);
    setParent(parent)
    setIsVisible(true);
  }

  return {
    isVisible,
    close,
    show,
    children,
    parent
  }
}

export const useDropdown = singletonHook({
  isVisible: false,
  close: () => {},
  show: () => {},
  children: undefined,
  parent: undefined
}, useDropdownImplementation);
import { ReactNode } from "react"

import "./input-error-message.css";

interface InputErrorMessageProps {
    children: ReactNode
}

export const InputErrorMessage: React.FC<InputErrorMessageProps> = ({ children }) => {
    return <span className="input-error-message">{children}</span>
}
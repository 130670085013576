import { useSubmitButton, useTextInput } from "morfhp"
import { useState } from "react";
import { Client } from "../../../domain/model/client";
import { Project } from "../../../domain/model/project";
import { validateProjectColor, validateProjectName } from "../../../domain/validators/project-validator";
import { ErrorMessage } from "../../atoms/error/error-message";
import { ColorInput, COLORS } from "../../atoms/input/color/color-input";
import { SaveButton } from "../../molecules/buttons/save-button";
import { TextInputGroup } from "../../molecules/input/text-input-group";

interface ProjectFormProps {
  client: Client,
  project?: Project,
  onSubmit: (newProject: Project) => Promise<void>
}

export const ProjectForm: React.FC<ProjectFormProps> = ({
  onSubmit,
  project,
  client
}) => {

  const [error, setError] = useState("");

  const nameInput = useTextInput({
    isRequired: true,
    validator: validateProjectName,
    Component: TextInputGroup,
    label: "Project name",
    defaultValue: !!project ? project.name : "",
    validateInitially: !!project,
    emptyValueMessage: "Please enter a name.",
  });

  const colorInput = useTextInput({
    isRequired: true,
    validator: validateProjectColor,
    Component: ColorInput,
    label: "Project color",
    defaultValue: project ? project.color : COLORS[0],
    validateInitially: !!project
  });

  const submitButton = useSubmitButton({
    inputs: [nameInput, colorInput],
    Component: SaveButton,
    onSubmit: onProjectCreate
  });

  async function onProjectCreate() {
    try {
      setError("");

      const name = nameInput.formValue.value!;
      const color = colorInput.formValue.value!;

      const newProject = !!project
        ? new Project(project.id, name, color, project.clientId)
        : Project.create(name, color, client.id)

      onSubmit(newProject);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <>
    {!!error && <ErrorMessage message={error} />}
    {nameInput.jsx}
    {colorInput.jsx}
    {submitButton.jsx}
  </>
}
import { faPlay } from "@fortawesome/free-solid-svg-icons"
import { SuccessButton } from "../../../molecules/buttons/success-button"

interface StartTrackingButtonProps {
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean,
  isLoading: boolean,
  text?: string
}

export const StartTrackingButton: React.FC<StartTrackingButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text = "Start"
}) => {
  return <SuccessButton
    onSubmit={onSubmit}
    icon={faPlay}
    text={text}
    canSubmit={canSubmit}
    isLoading={isLoading}
  />
}
import { useSubmitButton, useTextInput } from "morfhp";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail, validatePassword, validatePasswordConfirmation } from "../../../domain/validators/auth-validators";
import { createUser } from "../../../firebase/firebase-auth";
import { mapError } from "../../../firebase/firebase-error-mapper";
import { Card } from "../../atoms/card/card";
import { ErrorMessage } from "../../atoms/error/error-message";
import { Row } from "../../atoms/row/row";
import { Title } from "../../atoms/text/title";
import { RegisterButton } from "../../molecules/buttons/register-button";
import { RedirectButton } from "../../molecules/buttons/redirect-button";
import { TextInputGroup } from "../../molecules/input/text-input-group";

export const RegisterForm: React.FC = () => {

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const emailInput = useTextInput({
    isRequired: true,
    label: "Email",
    Component: TextInputGroup,
    validator: validateEmail
  });

  const passwordInput = useTextInput({
    isRequired: true,
    label: "Password",
    Component: TextInputGroup,
    validator: validatePassword,
    secureTextEntry: true
  });

  const confirmPasswordInput = useTextInput({
    isRequired: true,
    label: "Confirm password",
    Component: TextInputGroup,
    validator: (passwordConfirmation) => validatePasswordConfirmation(passwordInput.formValue.value, passwordConfirmation),
    secureTextEntry: true
  })

  const submitButton = useSubmitButton({
    inputs: [emailInput, passwordInput, confirmPasswordInput],
    Component: RegisterButton,
    onSubmit: onRegister
  });

  async function onRegister() {
    try {
      const email = emailInput.formValue.value!
      const password = passwordInput.formValue.value!
      await createUser(email, password);
      navigate("/overview");
    } catch (error) {
      setError(mapError(error));
    }
  }

  return <Card>
    <Title>Register</Title>
    {!!error && <ErrorMessage message={error} />}
    {emailInput.jsx}
    {passwordInput.jsx}
    {confirmPasswordInput.jsx}
    <Row justifyContent="space-between">
      {submitButton.jsx}
      <RedirectButton
        route="/login"
        text="Already have an account?"
      />
    </Row>
  </Card>

}
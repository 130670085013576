import { ReactNode, useRef } from "react"
import "./card.css";

interface CardProps {
    children?: ReactNode,
    minWidth?: number,
    maxWidth?: number,
    className?: string,
    id?: string
}

export const Card: React.FC<CardProps> = ({
    children,
    minWidth = 20,
    maxWidth,
    className = "",
    id
}) => {

    const ref = useRef(null);

    const innerId = id || "" + Math.floor(Math.random() * 1000);

    const style = {
        minWidth: minWidth ? `${minWidth}rem` : "auto",
        maxWidth: maxWidth ? `${maxWidth}rem` : "auto"
    }

    return <section
        ref={ref}
        id={innerId}
        className={`card ${className}`}
        style={style}
    >
        {children}
    </section>
}
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { initFirestore } from "../../firebase/firebase-firestore";
import { Logger } from "../../logging/logger";
import { Cache } from "../caching/cache";
import { Project } from "../model/project";
import { ProjectConverter } from "../serializers/project-serializer";

const PROJECT_COLLECTION = "project-collection/projects";

export class ProjectService {
  private readonly db = initFirestore();
  private static instance: ProjectService | undefined;
  private projectCache: Cache<Project[]> | undefined;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ProjectService();
    }
    return this.instance;
  }

  private updateCache(projects: Project[]) {
    if (this.projectCache) {
      this.projectCache.values = projects;
    } else {
      this.projectCache = new Cache(projects);
    }
  }

  public async saveProject(userId: string, project: Project) {
    const docRef = doc(this.db, userId, PROJECT_COLLECTION, project.id).withConverter(ProjectConverter);
    await setDoc(docRef, project);
    if (this.projectCache && !this.projectCache.isExpired()) {
      Logger.debug("Projects are cached, caching new project.");
      const allProjects = [...this.projectCache.values, project];
      this.updateCache(allProjects);
    }
  }

  public async getAllProjects(userId: string) {
    if (this.projectCache && !this.projectCache.isExpired()) {
      Logger.debug("Projects are cached, returning cached projects.");
      return this.projectCache.values;
    }
    const collectionRef = collection(this.db, userId, PROJECT_COLLECTION).withConverter(ProjectConverter);
    const docSnapshot = await getDocs(collectionRef);
    const projects = docSnapshot.docs.map(doc => doc.data());
    Logger.debug("Updating cached projects.");
    this.updateCache(projects);
    return projects;
  }

  public async getProject(userId: string, projectId: string) {
    if (this.projectCache && !this.projectCache.isExpired()) {
      const project = this.projectCache.values.find(cachedProject => cachedProject.id === projectId);
      if (project) {
        Logger.debug("Projects are cached, returning cached project.");
        return project;
      }
    }

    const docRef = doc(this.db, userId, PROJECT_COLLECTION, projectId).withConverter(ProjectConverter);
    const result = await getDoc(docRef);
    return result.data();
  }

  public async getProjects(userId: string, clientId: string) {
    if (this.projectCache && !this.projectCache.isExpired()) {
      const projects = this.projectCache.values.filter(project => project.clientId === clientId);
      if (projects.length > 0) {
        Logger.debug("Projects are cached, returning cached projects.");
        return projects;
      } else {
        Logger.debug(`No cached projects found for clientId '${clientId}'`);
      }
    }

    const collectionRef = collection(this.db, userId, PROJECT_COLLECTION).withConverter(ProjectConverter);
    const q = query(collectionRef, where("clientId", "==", clientId));
    const snapshot = await getDocs(q);
    const projects = snapshot.docs.map(doc => doc.data());
    Logger.debug(`Caching projects for clientId '${clientId}'.`);
    if (this.projectCache) {
      const allProjectsFiltered = this.projectCache.values.filter(project => project.clientId !== clientId);
      const allProjects = [...allProjectsFiltered, ...projects];
      this.updateCache(allProjects);
    } else {
      this.projectCache = new Cache(projects);
    }
    return projects;
  }

  public async deleteProject(userId: string, project: Project) {
    await deleteDoc(doc(this.db, userId, PROJECT_COLLECTION, project.id));
    if (this.projectCache && !this.projectCache.isExpired()) {
      Logger.debug("Projects are cached, removing deleted project.");
      const filteredProjects = this.projectCache.values.filter(cachedProject => cachedProject.id !== project.id);
      this.projectCache.values = filteredProjects;
    }
  }

  public async deleteAllProjectsForClient(userId: string, clientId: string) {
    const collectionRef = collection(this.db, userId, PROJECT_COLLECTION).withConverter(ProjectConverter);
    const q = query(collectionRef, where("clientId", "==", clientId));
    const snapshot = await getDocs(q);
    const deleteOperations = snapshot.docs.map(docSnapshot => {
      const project = docSnapshot.data();
      return this.deleteProject(userId, project);
    });

    await Promise.allSettled(deleteOperations);

    if (this.projectCache && !this.projectCache.isExpired()) {
      Logger.debug("Projects are cached, removing deleted project.");
      const filteredProjects = this.projectCache.values.filter(cachedProject => cachedProject.clientId !== clientId);
      this.projectCache.values = filteredProjects;
    }
  }
}
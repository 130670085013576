import { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { AuthenticationRule, useAuthentication } from "../../firebase/useAuthentication"
import { Logger } from "../../logging/logger";
import { PageContainer } from "../organisms/page-container/page-container"

import "./home-page.css";
import { Card } from "../atoms/card/card";
import { TimeIcon } from "../atoms/icons/time-icon";
import { BoardIcon } from "../atoms/icons/board-icon";
import { UsersIcon } from "../atoms/icons/users-icon";
import { RocketIcon } from "../atoms/icons/rocket-icon";

export const HomePage: React.FC = () => {

  const user = useAuthentication();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      Logger.debug("User is authenticated, redirecting to overview...");
      navigate("/overview");
    }
  }, [user, navigate])


  return <PageContainer
    authentication={AuthenticationRule.Either}
    title="Firetrack"
  >
    <div id="home-page-container">
      <section id="heading">
        <div>
          <h1>Firetrack</h1>
          <h2>Maximize Your Productivity</h2>
          <p>
            In today&apos;s fast-paced world, managing your time efficiently is the key to success.
            Whether you&apos;re a freelancer, a small business owner, or part of a dynamic team, <b>Firetrack</b> empowers you to take control of your projects like never before.
            With our intuitive platform, you can effortlessly create clients, organize projects, and track your time down to the minute — <em>all in one place</em>.
          </p>
          <a href="/register">Sign up!</a>
        </div>
        <img src={process.env.PUBLIC_URL + "/images/time-entry.png"} alt="A screenshot of a running time entry on the Firetrack platform." />
      </section>

      <section id="clients-and-projects">
        <img src={process.env.PUBLIC_URL + "/images/clients-and-projects.png"} alt="A screenshot of clients and project overview." />
      </section>

      <section id="features">
        <h2>Features</h2>

        <div className="feature">
          <Card>
            <div>
              <h3>Time Tracking</h3>
              <p>Track your time down to the minute with our intuitive time tracking feature. Whether you&apos;re working on a project, meeting with a client, or taking a break, you&apos;ll always know where your time is going.</p>
            </div>
            <TimeIcon />
          </Card>
        </div>

        <div className="feature">
          <Card>
            <BoardIcon />
            <div>
              <h3>Project Management</h3>
              <p>Stay organized with our powerful project management tools. Create projects, assign tasks, and set deadlines to keep your team on track and your clients happy.</p>
            </div>
          </Card>
        </div>

        <div className="feature">
          <Card>
            <div>
              <h3>Client Management</h3>
              <p>Keep your clients at the center of your business with our client management feature. Create clients, manage their details, and track your time and projects for each client.</p>
            </div>
            <UsersIcon />
          </Card>
        </div>

        <div className="feature">
          <Card>
            <RocketIcon />
            <div>
              <h3>Intuitive Kanban Boards for Task Management</h3>
              <p>Stay on top of your tasks with our intuitive kanban boards. Create tasks, move them through different stages and keep your projects moving forward.</p>
            </div>
          </Card>
        </div>
      </section>

      <section id="kanban">
        <img src={process.env.PUBLIC_URL + "/images/kanban.png"} alt="A screenshot of the Kanban board" />
      </section>

      <section id="cta">
        <h2>Ready to get started?</h2>
        <a href="/register">Sign up!</a>
      </section>


    </div>

  </PageContainer>
}
import { DateTime, Duration } from "luxon";
import { useState } from "react";
import { formatDurationAsString } from "../../../../helpers/duration-helper";
import { useProjectManager } from "../../../../hooks/useProjectManager";
import { useTimeTrackingHistory } from "../../../../hooks/useTimeTrackingHistory"
import { Card } from "../../../atoms/card/card"
import { KeyValueList } from "../../../atoms/text/key-value-list";
import { Title } from "../../../atoms/text/title";

export const LastWeekTimingCard: React.FC = () => {

  const [lastUpdate] = useState(DateTime.now());

  const timeTrackingHistory = useTimeTrackingHistory(lastUpdate);
  const projects = useProjectManager();

  const getTotalDuration = () => {

    if (!timeTrackingHistory.timeRecords || timeTrackingHistory.timeRecords.records.length === 0) {
      return `No time tracked during the last 7 days.`;
    }

    let duration = Duration.fromMillis(0);

    for (const record of timeTrackingHistory.timeRecords.records) {
      duration = duration.plus(record.getDuration());
    }

    return `Tracked ${formatDurationAsString(duration)} in the last 7 days.`;
  }

  const getMostTrackedProject = () => {

    if (!timeTrackingHistory.timeRecords || timeTrackingHistory.timeRecords.records.length === 0) {
      return `No projects tracked during the last 7 days.`;
    }

    let durations = new Map<string, Duration>();

    for (const record of timeTrackingHistory.timeRecords.records) {
      if (record.projectId) {
        if (durations.has(record.projectId)) {
          durations.set(record.projectId, durations.get(record.projectId)!.plus(record.getDuration()));
        } else {
          durations.set(record.projectId, record.getDuration());
        }
      }
    }

    if (durations.size === 0) {
      return `No projects tracked during the last 7 days.`;
    }

    let longestTrackedProject: [string, Duration] = Array.from(durations.entries())[0];

    for (const projectTracking of Array.from(durations.entries())) {
      if (longestTrackedProject[1].toMillis() < projectTracking[1].toMillis()) {
        longestTrackedProject = projectTracking;
      }
    }

    const longestProject = projects.projects?.find(project => project.id === longestTrackedProject[0]);

    if (!longestProject) {
      return `No projects tracked during the last 7 days.`;
    }

    let totalDuration = Duration.fromMillis(0);

    for (const record of timeTrackingHistory.timeRecords.records) {
      totalDuration = totalDuration.plus(record.getDuration());
    }

    const percentage = Math.round(longestTrackedProject[1].toMillis() / totalDuration.toMillis() * 100);

    return `Project '${longestProject.name}', with a total of ${formatDurationAsString(longestTrackedProject[1])} (${percentage}% of total time tracked).`;

  }

  return <Card>
    <Title h={3}>Last 7 days</Title>
    <KeyValueList
      keyValues={[
        { property: "Total time tracked", value: getTotalDuration() },
        { property: "Most tracked project", value: getMostTrackedProject() }
      ]}
    />
  </Card>
}
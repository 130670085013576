const DEFAULT = "Something went wrong, please try again.";

const errors = new Map()
errors.set("auth/email-already-exists", "A user with this email already exists.");
errors.set("auth/email-already-in-use", "A user with this email already exists.");
errors.set("auth/id-token-expired", "Authentication expired, please log in again.");
errors.set("auth/id-token-revoked", "Authentication failed.");
errors.set("auth/internal-error", "Authentication failed");
errors.set("auth/invalid-credential", "Incorrect credentials, please verify the entered information.");
errors.set("auth/invalid-display-name", "Your display name is not valid, please try another value.");
errors.set("auth/invalid-email", "Your email is invalid, please try another one.");
errors.set("auth/user-not-found", "No user found for these credentials, please register first.");
errors.set("auth/network-request-failed", "A network error occured, please try again.");
errors.set("auth/wrong-password", "Incorrect password.");
errors.set("auth/too-many-requests", "Too many failed login attempts, please try again later.");

export const mapError = (error: Error | unknown): string => {

  const castError = error as Error
  if (!castError || !castError.message) {
    return DEFAULT;
  }

  const message = castError.message;

  const openingBracketIndex = message.indexOf("(");
  const closingBracketIndex = message.indexOf(")");

  const strippedMessage = message.substring(openingBracketIndex + 1, closingBracketIndex);
  return errors.get(strippedMessage) || DEFAULT;
}



import { ReactNode } from "react";
import { TableRow } from "./table-row"

import "./table.css";

export enum EntryType {
  VALUE,
  BUTTON,
  NODE
}

export const valueEntry = (
  value: string | ReactNode,
  className = ""
) => ({
  value,
  type: EntryType.VALUE,
  className
});

export const buttonEntry = (
  value: ReactNode,
  className = ""
) => ({
  value,
  type: EntryType.BUTTON,
  className
})

export const nodeEntry = (
  value: ReactNode,
  className = ""
) => ({
  value,
  type: EntryType.NODE,
  className
})

export interface TableEntry {
  type: EntryType,
  value: string | ReactNode,
  className?: string,
  colSpan?: number
}

interface TableProps {
  headings: string[],
  rows?: TableEntry[][],
  children?: ReactNode,
  className?: string
}

export const Table: React.FC<TableProps> = ({
  headings,
  rows,
  children,
  className = ""
}) => {

  return <table cellSpacing={0} className={className}>
    <thead>
      <tr>
        {headings.map((heading, index) => <th key={index}>{heading}</th>)}
      </tr>
    </thead>
    <tbody>
      {!!rows && rows.map((row, index) => <TableRow key={index} entries={row} />)}
      {children}
    </tbody>
  </table>
}
import { DateTime, Duration } from "luxon";

export class Cache<T> {

  private timestamp = DateTime.now();

  constructor(private _values: T, private readonly duration = Duration.fromMillis(60000) /* One minute caching */) { }

  isExpired(): boolean {
    return DateTime.now().toMillis() > this.timestamp.plus(this.duration).toMillis();
  }

  get values(): T {
    return this._values;
  }

  set values(newValues: T) {
    this._values = newValues;
    this.timestamp = DateTime.now();
  }
}
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { useSubmitButton, useTextInput } from "morfhp"
import { useState } from "react"
import { validateEmail } from "../../domain/validators/auth-validators"
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Card } from "../atoms/card/card"
import { ErrorMessage } from "../atoms/error/error-message"
import { Row } from "../atoms/row/row"
import { SuccessMessage } from "../atoms/success/success-message"
import { Title } from "../atoms/text/title"
import { SendEmailButton } from "../molecules/buttons/send-email-button"
import { TextInputGroup } from "../molecules/input/text-input-group"
import { PageContainer } from "../organisms/page-container/page-container"

export const ResetPasswordPage = () => {

  const auth = getAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const emailInput = useTextInput({
    isRequired: true,
    label: "Please enter your email",
    Component: TextInputGroup,
    validator: validateEmail,
    emptyValueMessage: "Please enter an email."
  });

  const onResetPasswordClicked = async () => {
    try {
      await sendPasswordResetEmail(auth, emailInput.formValue.value!);
      setError("");
      setSuccess("Password reset email sent.");
    } catch (error) {
      setError("Something went wrong while trying to send a password reset email.");
    }
  }

  const sendEmailButton = useSubmitButton({
    inputs: [emailInput],
    Component: SendEmailButton,
    onSubmit: onResetPasswordClicked
  });

  return <PageContainer
    authentication={AuthenticationRule.MustNotBeAuthenticated}
    title="Reset password"
    justifyContent="center"
  >
    <Row justifyContent="center">
      <Card maxWidth={30}>
        <Title>Reset your password.</Title>
        {!!error && <ErrorMessage message={error} />}
        {!!success && <SuccessMessage message={success} />}
        {!success && emailInput.jsx}
        {!success && sendEmailButton.jsx}
      </Card>
    </Row>
  </PageContainer>
}
import { initFirestore } from "../../firebase/firebase-firestore";
import { Client } from "../model/client";
import { doc, getDocs, setDoc, collection, deleteDoc } from "firebase/firestore";
import { ClientConverter } from "../serializers/client-serializer";
import { ProjectService } from "./project-service";
import { Cache } from "../caching/cache";
import { Logger } from "../../logging/logger";

const CLIENT_COLLECTION = "client-collection/clients";

export class ClientService {
  private readonly db = initFirestore();
  private static instance: ClientService | undefined;
  private clientCache: Cache<Client[]> | undefined;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new ClientService();
    }
    return this.instance;
  }

  public async saveClient(userId: string, client: Client) {
    const docRef = doc(this.db, userId, CLIENT_COLLECTION, client.id).withConverter(ClientConverter);
    await setDoc(docRef, client);
    if (this.clientCache && !this.clientCache.isExpired()) {
      Logger.debug("Clients are cached, adding new client to cached clients.");
      const allClients = [...this.clientCache.values, client];
      this.clientCache.values = allClients;
    }
  }

  public async getClients(userId: string) {
    if (this.clientCache && !this.clientCache.isExpired()) {
      Logger.debug("Clients are cached, returning list of cached clients.");
      return this.clientCache.values;
    }
    const collectionRef = collection(this.db, userId, CLIENT_COLLECTION).withConverter(ClientConverter);
    const querySnapshot = await getDocs(collectionRef);
    const clients = querySnapshot.docs.map(doc => doc.data());

    Logger.debug("Caching clients.");
    if (this.clientCache) {
      this.clientCache.values = clients;
    } else {
      this.clientCache = new Cache(clients);
    }

    return clients;
  }

  public async deleteClient(userId: string, client: Client) {
    await ProjectService.getInstance().deleteAllProjectsForClient(userId, client.id);
    await deleteDoc(doc(this.db, userId, CLIENT_COLLECTION, client.id));

    if (this.clientCache && !this.clientCache.isExpired()) {
      Logger.debug("Clients are cached, removing deleted client from cached clients.");
      const filteredClients = this.clientCache.values.filter(cachedClient => cachedClient.id !== client.id);
      this.clientCache.values = filteredClients;
    }
  }
}
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { TodoItem } from "../../../../domain/model/todo-item"
import { useActiveTimeTracking } from "../../../../hooks/useActiveTimeTracking"
import { useModal } from "../../../../hooks/useModal"
import { TodoManager } from "../../../../hooks/useTodoManager"
import { Row } from "../../../atoms/row/row"
import { DangerButton } from "../../../molecules/buttons/danger-button"
import { WarningButton } from "../../../molecules/buttons/warning-button"
import { StartItemTrackingButton } from "../../buttons/time-tracking/start-item-tracking-button"
import { ConfirmationDialog } from "../../dialogs/confirmation-dialog"

import "./todo-item-buttons.css";
import { TodoItemForm } from "./todo-item-form"

interface TodoItemButtonsProps {
  item: TodoItem,
  todoManager: TodoManager
}

export const TodoItemButtons: React.FC<TodoItemButtonsProps> = ({
  item,
  todoManager
}) => {


  const activeTimeTracking = useActiveTimeTracking();
  const modal = useModal();

  const onEditButtonSubmit = async (todoItem: TodoItem) => {
    await todoManager.updateTodoItem(todoItem);
    modal.close();
  }

  const onEditButtonClick = () => {
    modal.show({
      title: "Edit to do item",
      children: <TodoItemForm onSubmit={onEditButtonSubmit} item={item} />
    });
  }

  const onDeleteButtonSubmit = async () => {
    await todoManager.removeTodoItem(item);
    modal.close();
  }

  const onDeleteButtonClicked = () => {
    modal.show({
      title: "Delete to do item?",
      children: <ConfirmationDialog
        onCancel={() => modal.close()}
        onConfirm={onDeleteButtonSubmit}
        text={[
          "Are you sure you want to delete this to do item,",
          "This actions can not be undone."
        ]}
      />
    })
  }


  return <div className="todo-item-buttons">
    <Row justifyContent="right" overflowY="hidden" margin="top">
      <StartItemTrackingButton onClick={() => activeTimeTracking.start(item.description, item.projectId)} />
      <WarningButton
        collapsible={true}
        canSubmit={true}
        isLoading={false}
        onSubmit={onEditButtonClick}
        text="Edit"
        icon={faPencil}
      />
      <DangerButton
        collapsible={true}
        text="Delete"
        canSubmit={true}
        isLoading={false}
        onSubmit={onDeleteButtonClicked}
        icon={faTrash}
      />
    </Row>
  </div>
}
import { useCallback, useEffect, useState } from "react";
import { Client } from "../domain/model/client";
import { Project } from "../domain/model/project";
import { ProjectService } from "../domain/service/project-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";
import { Logger } from "../logging/logger";

export const useClientProjectManager = (client: Client) => {

  const user = useMustBeAuthenticated();

  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const saveProject = useCallback(async (project: Project) => {
    if (user) {
      Logger.debug(`Adding new project for user with id '${user.uid}'.`);
      await ProjectService.getInstance().saveProject(user.uid, project);
      const projects = await ProjectService.getInstance().getProjects(user.uid, client.id);
      setProjects(projects);
    } else {
      Logger.debug("No user, could not add project.");
    }
  }, [user, client])

  const getProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      if (user) {
        Logger.debug(`Getting projects for user ${user.uid} and client ${client.id}`);
        const projects = await ProjectService.getInstance().getProjects(user.uid, client.id);
        setProjects(projects);
      } else {
        Logger.debug(`No user, could not get projects.`);
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user, client])

  const deleteProject = useCallback(async (project: Project) => {
    setIsLoading(true);
    try {
      if (user) {
        Logger.debug(`Removing project with id "${project.id}".`);
        await ProjectService.getInstance().deleteProject(user.uid, project);
        const projects = await ProjectService.getInstance().getProjects(user.uid, client.id);
        setProjects(projects);
      } else {
        Logger.debug("No user, could not remove project");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user, client])

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return {
    projects,
    isLoading,
    error,
    saveProject,
    deleteProject
  };
}
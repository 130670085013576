import { useCallback, useEffect, useState } from "react";
import { TodoBoard } from "../domain/model/todo-board";
import { TodoColumn } from "../domain/model/todo-column";
import { TodoItem } from "../domain/model/todo-item";
import { TodoService } from "../domain/service/todo-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication"

export interface TodoManager {
  todoBoards: TodoBoard[],
  updateTodoItem: (todoItem: TodoItem) => Promise<void>,
  updateTodoColumn: (todoColumn: TodoColumn) => Promise<void>,
  updateTodoBoard: (todoBoard: TodoBoard) => Promise<void>,
  addTodoBoard: (todoBoard: TodoBoard) => Promise<void>,
  addTodoColumn: (todoColumn: TodoColumn, targetBoard: TodoBoard) => Promise<void>,
  addTodoItem: (todoItem: TodoItem, targetColumn: TodoColumn) => Promise<void>,
  removeTodoItem: (todoItem: TodoItem) => Promise<void>,
  removeTodoColumn: (todoColumn: TodoColumn) => Promise<void>,
  removeTodoBoard: (todoBoard: TodoBoard) => Promise<void>,
  moveTodoItem: (todoItem: TodoItem, direction: "left" | "right" | "up" | "down") => Promise<void>,
  moveTodoColumn: (todoColumn: TodoColumn, direction: "left" | "right") => Promise<void>,
  error: string
}

export const useTodoManager: () => TodoManager = () => {

  const user = useMustBeAuthenticated();

  const [todoBoards, setTodoBoards] = useState<TodoBoard[]>([]);
  const [error, setError] = useState("");

  const getBoards = useCallback(async () => {
    try {
      if (user) {
        const boards = await TodoService.getInstance().getTodos(user.uid);
        setTodoBoards(boards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [user]);

  useEffect(() => {
    getBoards();
  }, [getBoards]);

  const updateTodoItem = useCallback(async (todoItem: TodoItem) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.updateItem(todoItem);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const updateTodoColumn = useCallback(async (todoColumn: TodoColumn) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.updateColumn(todoColumn);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const updateTodoBoard = useCallback(async (todoBoard: TodoBoard) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        const foundIndex = clonedBoards.findIndex(board => board.id === todoBoard.id);
        if (foundIndex >= 0) {
          clonedBoards[foundIndex] = todoBoard;
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [user, todoBoards]);

  const addTodoBoard = useCallback(async (todoBoard: TodoBoard) => {
    try {
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        clonedBoards.push(todoBoard);
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user])

  const addTodoColumn = useCallback(async (todoColumn: TodoColumn, targetBoard: TodoBoard) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          if (board.id === targetBoard.id) {
            board.columns.push(todoColumn);
          }
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const addTodoItem = useCallback(async (todoItem: TodoItem, targetColumn: TodoColumn) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.addTodoItem(todoItem, targetColumn);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const removeTodoItem = useCallback(async (todoItem: TodoItem) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.removeTodoItem(todoItem);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const removeTodoColumn = useCallback(async (todoColumn: TodoColumn) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.removeColumn(todoColumn);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);

      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const removeTodoBoard = useCallback(async (todoBoard: TodoBoard) => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards
          .map(board => board.clone())
          .filter(board => board.id !== todoBoard.id);
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const moveTodoItem = useCallback(async (todoItem: TodoItem, direction: "left" | "right" | "up" | "down") => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.moveItem(todoItem, direction);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user]);

  const moveTodoColumn = useCallback(async (todoColumn: TodoColumn, direction: "left" | "right") => {
    try {
      setError("");
      if (user) {
        const clonedBoards = todoBoards.map(board => board.clone());
        for (const board of clonedBoards) {
          board.moveColumn(todoColumn, direction);
        }
        const updatedBoards = await TodoService.getInstance().saveTodos(user.uid, clonedBoards);
        setTodoBoards(updatedBoards);
      }
    } catch (error) {
      setError((error as Error).message);
    }
  }, [todoBoards, user])

  const todoManager: TodoManager = {
    todoBoards,
    updateTodoItem,
    updateTodoColumn,
    updateTodoBoard,
    addTodoBoard,
    addTodoColumn,
    addTodoItem,
    removeTodoItem,
    removeTodoColumn,
    removeTodoBoard,
    moveTodoItem,
    moveTodoColumn,
    error
  }

  return todoManager;

}
import validator from "valivalue";
import { validateUuid } from "../validators/uuid-validator";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { validateTodoItemDescription } from "../validators/todo-item-validators";

export class TodoItem {

  constructor(
    readonly id: string,
    readonly description: string,
    readonly creationTimestamp: DateTime,
    readonly projectId?: string
  ) {
    validateUuid(id);
    validateTodoItemDescription(description);
    validator.objects.validateNotNullOrUndefined(creationTimestamp, "Todo item creation timestamp");
    validator.timestamps.validateIsInPast(creationTimestamp, "Todo item creation timestamp");
  }

  clone() {
    return new TodoItem(
      this.id,
      this.description,
      this.creationTimestamp,
      this.projectId
    );
  }

  static create(description: string, projectId?: string) {
    return new TodoItem(
      uuidv4(),
      description,
      DateTime.now(),
      projectId
    );
  }
}
import validator from "valivalue";

export const validateProjectName = (name: string | undefined) => {
  validator.objects.validateNotNullOrUndefined(name, "Project name");
  return validator.strings.validateMinAndMaxLength(name!, 2, 64, "Project name");
}

export const validateProjectColor = (color: string | undefined) => {
  validator.objects.validateNotNullOrUndefined(color, "Project color");
  return validator.strings.validateNotEmpty(color!, "Project color");
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import "./text-input.css";
import { useState } from "react";

interface TextInputProps {
    id: string,
    isRequired?: boolean,
    onValueChange: (input: string) => void,
    isError: boolean,
    secureTextEntry?: boolean,
    value?: string,
    placeholder?: string
}

export const TextInput: React.FC<TextInputProps> = ({
    id,
    isRequired,
    onValueChange,
    isError,
    secureTextEntry = false,
    value,
    placeholder = ""
}) => {

    const [showPassword, setShowPassword] = useState(false);

    const className = isError
        ? "text-input text-input-error"
        : "text-input"

    return <div className={className}>
        <input
            id={id}
            onChange={(event) => { onValueChange(event.target.value); }}
            required={isRequired}
            type={secureTextEntry && !showPassword ? "password" : "text"}
            value={value}
            placeholder={placeholder}
        />
        {secureTextEntry && <FontAwesomeIcon
            className="text-input-show-password"
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
        />
        }
    </div>
}
import { faArrowDown, faArrowUp, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import { Project } from "../../../../domain/model/project"
import { useDropdown } from "../../../../hooks/useDropdown"
import { useProjectManager } from "../../../../hooks/useProjectManager"
import { Color } from "../../color/color"
import { Row } from "../../row/row"
import { CenteredSpinner } from "../../spinner/centered-spinner"

import "./project-input.css";

interface ProjectInputProps {
  error?: string,
  isLoading?: boolean,
  label: string,
  value?: string | undefined,
  onValueChange: (projectId: string | undefined) => void | Promise<void>
}

interface ProjectDropdownProps {
  projects?: Project[],
  onValueChange: (projectId: string) => void | Promise<void>
}

const ProjectDropdown: React.FC<ProjectDropdownProps> = ({
  projects = [],
  onValueChange
}) => {

  const [filter, setFilter] = useState("");
  const inputField = useRef(null);

  useEffect(() => {
    if (inputField && inputField.current && !!(inputField.current as any).focus) {
      (inputField.current as any).focus();
    }
  }, [inputField]);

  const filteredProjects = projects
    .filter(project => project.name.toLowerCase().includes(filter.toLowerCase()))
    .slice(0, 5)

  return <div className="project-input-dropdown">
    <input ref={inputField} placeholder="filter" onChange={(e) => setFilter(e.target.value)} />
    {
      filteredProjects.length
        ? filteredProjects.map(project => {
          return <div key={project.id} className="project-entry" onClick={() => {
            onValueChange(project.id);
          }}>
            <Color color={project.color} />
            <p>
              {project.name}
            </p>
          </div>
        })
        : <p className="project-entry-empty">No projects found.</p>
    }
  </div>

}

export const ProjectInput: React.FC<ProjectInputProps> = ({
  value,
  onValueChange,
  error,
  isLoading,
  label = "Select a project",
}) => {

  const projectManager = useProjectManager();
  const dropdown = useDropdown();
  const container = useRef(null);

  const project = !!projectManager.projects
    ? projectManager.projects.find(project => project.id === value)
    : undefined;

  const onDropdownClick = (projectId: string) => {
    dropdown.close();
    onValueChange(projectId);
  }

  return <div ref={container} className="project-input">
    {
      projectManager.isLoading
        ? <CenteredSpinner />
        : <>
          <Row>
            {!!project && <Color color={project.color} />}
            <p>{!!project ? project.name : label}</p>
          </Row>
          <Row>
            <FontAwesomeIcon
              className="clear-button"
              icon={faXmark}
              onClick={() => {
                onValueChange("");
                dropdown.close();
              }}
            />
            <FontAwesomeIcon
              className="dropdown-button"
              icon={dropdown.isVisible ? faArrowUp : faArrowDown}
              onClick={() => {
                if (!dropdown.isVisible) {
                  dropdown.show({ children: <ProjectDropdown onValueChange={onDropdownClick} projects={projectManager.projects} />, parent: container })
                } else {
                  dropdown.close();
                }
              }}
            />
          </Row>
        </>
    }
  </div>

}
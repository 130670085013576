import { useCallback, useEffect, useState } from "react";
import { Project } from "../domain/model/project";
import { ProjectService } from "../domain/service/project-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";

export const useSingleProjectManager = (projectId: string | undefined) => {

  const user = useMustBeAuthenticated();

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState<Project>();

  const fetchProject = useCallback(async () => {
    if (user && projectId) {
      const project = await ProjectService.getInstance().getProject(user.uid, projectId);
      setProject(project);
    }
    setIsLoading(false);
  }, [projectId, user]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  return {
    project,
    isLoading
  };
}
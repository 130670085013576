import { DateTime } from "luxon";
import { TimeRecord } from "../../../domain/model/time-record"
import { useSingleProjectManager } from "../../../hooks/useSingleProjectManager";
import { Color } from "../../atoms/color/color";
import { Row } from "../../atoms/row/row";
import { buttonEntry, nodeEntry, valueEntry } from "../../atoms/tables/table";
import { DangerButton } from "../buttons/danger-button";
import { WarningButton } from "../buttons/warning-button";
import { TableRow } from "../../atoms/tables/table-row";
import { faPencil, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { SuccessButton } from "../buttons/success-button";

interface TimeRecordRowProps {
  record: TimeRecord,
  recordsByDay: TimeRecord[],
  startTracking: (record: TimeRecord) => void | Promise<void>,
  deleteTimeRecord: (record: TimeRecord) => void | Promise<void>,
  updateTimeRecord: (record: TimeRecord) => void | Promise<void>
}

export const TimeRecordRow: React.FC<TimeRecordRowProps> = ({
  record,
  recordsByDay,
  startTracking,
  deleteTimeRecord,
  updateTimeRecord
}) => {

  const projectManager = useSingleProjectManager(record.projectId);

  const totalDuration = recordsByDay
    .map(record => record.getDuration())
    .reduce((previous, current) => previous.plus(current))
    .toMillis();

  const percentage = Math.floor(record.getDuration().toMillis() / totalDuration * 100);

  const buttons = <>
    <SuccessButton
      collapsible={true}
      canSubmit={true}
      isLoading={false}
      onSubmit={() => startTracking(record)}
      text="Start tracking"
      icon={faPlay}
    />
    <WarningButton
      collapsible={true}
      canSubmit={true}
      isLoading={false}
      onSubmit={() => updateTimeRecord(record)}
      text={"Edit"}
      icon={faPencil}
    />
    <DangerButton
      collapsible={true}
      canSubmit={true}
      isLoading={false}
      onSubmit={() => deleteTimeRecord(record)}
      text="Delete"
      icon={faTrash}
    />
  </>

  const projectEntry = !projectManager.project
    ? <></>
    : <Row>
      <Color color={projectManager.project.color} />
      <p>{projectManager.project.name}</p>
    </Row>

  const entries = [
    valueEntry(record.description || "No description"),
    nodeEntry(projectEntry),
    valueEntry(record.start.toLocaleString(DateTime.TIME_SIMPLE)),
    valueEntry(record.end!.toLocaleString(DateTime.TIME_SIMPLE)),
    valueEntry(record.getDurationAsString()),
    valueEntry(`${percentage}%`),
    buttonEntry(buttons, "show-on-hover")
  ]

  return <TableRow key={record.id} entries={entries} />
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { routes } from './routes/routes';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { initFirebase } from './firebase/firebase-app';

const router = createBrowserRouter(routes);
initFirebase();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { ReactNode } from "react"

import "./input-label.css";

interface InputLabelProps {
    htmlFor: string,
    isError: boolean,
    children: ReactNode,
    isRequired: boolean
}

export const InputLabel: React.FC<InputLabelProps> = ({
    isError,
    isRequired,
    children,
    htmlFor
}) => {

    const className = isError
        ? "input-label input-label-error"
        : "input-label"

    return <label
        className={className}
        htmlFor={htmlFor}
    >
        {children}
        {
            isRequired && <span className="label-required-mark">*</span>
        }
    </label>
}
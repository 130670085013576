import { ReactNode } from "react"
import "./title.css"

interface TitleProps {
    children: ReactNode,
    h?: 1 | 2 | 3 | 4 | 5 | 6,
    margin?: boolean,
    className?: string
}

export const Title: React.FC<TitleProps> = ({
    children,
    h = 2,
    margin = true,
    className
}) => {

    const innerClassName = !!className
        ? `${className} ${margin ? "" : "no-margin"}`
        : margin ? "" : "no-margin";

    return (() => {
        switch (h) {
            case 1: 
                return <h1 className={innerClassName}>{children}</h1>;
            case 3:
                return <h3 className={innerClassName}>{children}</h3>
            case 4:
                return <h4 className={innerClassName}>{children}</h4>
            case 5:
                return <h5 className={innerClassName}>{children}</h5>
            case 6:
                return <h6 className={innerClassName}>{children}</h6>
            default:
                return <h2 className={innerClassName}>{children}</h2>
        }
    })();

}
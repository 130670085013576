import { LightButton } from "./light-button"
import { faSquareUpRight } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { useNavigate } from "react-router-dom";

interface RedirectButtonProps {
  route: string,
  text: string,
  loadingText?: string
}

export const RedirectButton: React.FC<RedirectButtonProps> = ({
  route,
  text,
  loadingText = "Redirecting"
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onClick = async () => {
    setIsLoading(true);
    navigate(route)
  }

  return <LightButton
    icon={faSquareUpRight}
    canSubmit={true}
    isLoading={isLoading}
    onSubmit={onClick}
    text={text}
    loadingText={loadingText}
  />
}
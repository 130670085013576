import { faEllipsisVertical, faEye, faEyeSlash, faPlus } from "@fortawesome/free-solid-svg-icons"
import { useRef, useState } from "react"
import { TodoColumn } from "../../../../domain/model/todo-column"
import { TodoItem } from "../../../../domain/model/todo-item"
import { useModal } from "../../../../hooks/useModal"
import { Card } from "../../../atoms/card/card"
import { FilterInput } from "../../../atoms/input/text/filter-input"
import { Row } from "../../../atoms/row/row"
import { Title } from "../../../atoms/text/title"
import { LightButton } from "../../../molecules/buttons/light-button"
import { TodoItemCard } from "../item/todo-item-card"
import { TodoItemForm } from "../item/todo-item-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./todo-column-section.css";
import { TodoManager } from "../../../../hooks/useTodoManager"
import { useDropdown } from "../../../../hooks/useDropdown"
import { TodoColumnDropdown } from "../../../molecules/todo/todo-column-dropdown"
import { Spinner } from "../../../atoms/spinner/spinner"

interface TodoColumnSectionProps {
  column: TodoColumn,
  todoManager: TodoManager
}

export const TodoColumnSection: React.FC<TodoColumnSectionProps> = ({
  column,
  todoManager
}) => {

  const modal = useModal();
  const ref = useRef(null);
  const dropdown = useDropdown();
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const applyFilter = (item: TodoItem) => {
    const lowerCaseFilter = filter.toLowerCase();
    return item.description.toLowerCase().includes(lowerCaseFilter);
  }

  const onAddTodoItem = async (todoItem: TodoItem) => {
    await todoManager.addTodoItem(todoItem, column);
    modal.close();
  }

  const onAddButtonClick = () => {
    modal.show({
      title: "Add to do item",
      children: <TodoItemForm onSubmit={onAddTodoItem} />
    })
  }

  const toggleDropdown = () => {
    if (!dropdown.isVisible) {
      dropdown.show({
        parent: ref,
        children: <TodoColumnDropdown
          column={column}
          todoManager={todoManager}
        />
      })
    } else {
      dropdown.close();
    }
  }

  const onShowButtonClicked = async () => {
    setIsLoading(true);
    try {
      const updatedColumn = new TodoColumn(column.id, column.title, column.creationTimestamp, column.items, !column.isHidden);
      await todoManager.updateTodoColumn(updatedColumn);
    } catch (error) {
      // TODO show error to user
      console.log((error as Error).message);
    }
    setIsLoading(false);
  }

  return <Card id={column.id} className="todo-column appear-gradually">
    <Row margin="bottom" justifyContent="space-between">
      <Row overflowX="hidden">
        <Title h={3} margin={false}>
          {column.title}
        </Title>
        {isLoading
          ? <Spinner />
          : <FontAwesomeIcon
            className="todo-column-hidden-icon"
            icon={column.isHidden ? faEye : faEyeSlash}
            onClick={onShowButtonClicked}
            title={column.isHidden ? "Show" : "Hide"}
          />}
      </Row>
      <Row>
        <LightButton
          canSubmit={true}
          isLoading={false}
          onSubmit={onAddButtonClick}
          text="Add to do item"
          collapsible={true}
          icon={faPlus}
        />
        <FontAwesomeIcon
          ref={ref}
          icon={faEllipsisVertical}
          className="todo-column-dropdown-button"
          onClick={toggleDropdown}
        />

      </Row>
    </Row>
    {
      !!column.isHidden
        ? !!column.items.length ? <p>{column.items.length} to do items.</p> : <p>No items yet.</p>
        : <>
          {
            !!column.items.length && <FilterInput
              value={filter}
              onValueChange={setFilter}
            />
          }
          {!column.items.length
            ? <p>No items yet.</p>
            : column.items.filter(applyFilter).map(item => {
              return <TodoItemCard
                key={item.id}
                item={item}
                todoManager={todoManager}
              />
            })
          }
        </>
    }
  </Card>
}
import { DateTime } from "luxon";
import { TimeRecord } from "./time-record";

enum Order {
  ASCENDING,
  DESCENDING
}

const sortDateTime = (first: DateTime, second: DateTime, order = Order.ASCENDING) => {
  if (order === Order.ASCENDING) {
    return first < second
      ? -1
      : first > second
        ? 1
        : 0
  }
  return first > second
    ? -1
    : first < second
      ? 1
      : 0;
}

const sortTimeRecordsAscending = (firstRecord: TimeRecord, secondRecord: TimeRecord) => {
  return sortDateTime(firstRecord.start, secondRecord.start);
}

const dateToString = (timestamp: DateTime) => {
  const {
    day,
    month,
    year
  } = timestamp;

  const dayAsString = (day + "").length === 1
    ? `0${day}`
    : `${day}`;

  const monthAsString = (month + "").length === 1
    ? `0${month}`
    : `${month}`

  return `${year}-${monthAsString}-${dayAsString}`;
}

export const orderByDay = (timeRecords: TimeRecord[]) => {
  const recordsByDay = new Map<string, TimeRecord[]>();
  const days = [];

  for (const record of timeRecords) {
    const currentDay = dateToString(record.start);
    recordsByDay.set(currentDay, [...(recordsByDay.get(currentDay) || []), record]);
  }

  for (const date of Array.from(recordsByDay.keys())) {
    const orderedRecordsForDate = recordsByDay.get(date)!.sort(sortTimeRecordsAscending);
    recordsByDay.set(date, orderedRecordsForDate);
    days.push(date);
  }

  return {
    recordsByDay,
    days: days
      .map(day => DateTime.fromISO(day))
      .sort((first, second) => sortDateTime(first, second, Order.DESCENDING))
      .map(dateToString)
  }
}
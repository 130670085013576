import "./success-message.css";

interface SuccessMessageProps {
  message: string
}

export const SuccessMessage: React.FC<SuccessMessageProps> = ({
  message
}) => {
  return <div className="success-message">
    {message}
  </div>
}
import { DocumentData, DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Client } from "../model/client"

export const ClientConverter = {
  toFirestore: (client: Client) => {
    return {
      id: client.id,
      name: client.name
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
    const data = snapshot.data(options);

    if (!data) {
      throw new Error("Failed to load client data.");
    }

    const {
      id,
      name
    } = data;

    return new Client(id, name);
  }
}
import { DocumentData, DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { TimeRecord } from "../model/time-record";
import { DateTime } from "luxon";
import { toDateInteger } from "../../helpers/timestamp-helpers";

interface SerializedTimeRecord {
  id: string,
  description: string,
  start: string,
  startInteger: number,
  end?: string,
  endInteger?: number,
  projectId: string
}

export const TimeRecordConverter = {
  toFirestore: (record: TimeRecord) => {
    const serializedRecord: SerializedTimeRecord = {
      id: record.id,
      description: record.description,
      start: record.start.toISO({ includeOffset: false}),
      startInteger: toDateInteger(record.start),
      projectId: record.projectId || ""
    }

    if (record.end) {
      serializedRecord.end = record.end.toISO({ includeOffset: false });
      serializedRecord.endInteger = toDateInteger(record.end)
    }

    return serializedRecord;
  },
  fromFirestore: (snapshot: DocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    if (!data) {
      throw new Error("Time record is empty.");
    }

    const {
      id,
      description,
      projectId
    } = data;

    const start = DateTime.fromISO(data.start);
    const end = data.end
      ? DateTime.fromISO(data.end)
      : undefined;

    return new TimeRecord(id, description, start, end, projectId);
  }
};
import "./error-message.css";

interface ErrorMessageProps {
  message: string
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message
}) => {
  return <div className="error-message">
    {message}
  </div>
}
import { Client } from "../../../domain/model/client";
import { Project } from "../../../domain/model/project";
import { useActiveTimeTracking } from "../../../hooks/useActiveTimeTracking";
import { useModal } from "../../../hooks/useModal";
import { Color } from "../../atoms/color/color";
import { Row } from "../../atoms/row/row";
import { buttonEntry, nodeEntry, Table, valueEntry } from "../../atoms/tables/table";
import { DeleteProjectButton } from "../buttons/project/delete-project-button";
import { EditProjectButton } from "../buttons/project/edit-project-button";
import { StartItemTrackingButton } from "../buttons/time-tracking/start-item-tracking-button";
import { ProjectForm } from "./project-form";

interface ProjectTableProps {
  projects: Project[],
  onProjectUpdate: (project: Project) => Promise<void>,
  deleteProject: (project: Project) => Promise<void>,
  client: Client
}

export const ProjectTable: React.FC<ProjectTableProps> = ({
  projects,
  onProjectUpdate,
  deleteProject,
  client
}) => {

  const modal = useModal();
  const activeTimeTracking = useActiveTimeTracking();

  const innerOnUpdateClick = async (project: Project) => {
    await onProjectUpdate(project);
    modal.close();
  };

  const rows = !projects.length
    ? [[valueEntry("No projects yet.")]]
    : projects.map(project => {
      const buttons = <>
        <StartItemTrackingButton
          onClick={async () => activeTimeTracking.start("", project.id)}
        />
        <EditProjectButton onClick={() => modal.show({
          children: <ProjectForm project={project} onSubmit={innerOnUpdateClick} client={client} />,
          title: "Edit project"
        })} />
        <DeleteProjectButton deleteProject={deleteProject} project={project} />
      </>
      const projectNameAndColor = <Row margin="left">
        <Color color={project.color} />
        <p>{project.name}</p>
      </Row>;
      return [
        nodeEntry(projectNameAndColor),
        buttonEntry(buttons, "show-on-hover"),
      ]
    });

  return <Table
    className="appear-gradually"
    headings={["Project name", "Actions"]}
    rows={rows}
  />
};
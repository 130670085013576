import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { sendEmailVerification } from "firebase/auth";
import { useState } from "react";
import { useAuthentication } from "../../../firebase/useAuthentication"
import { Card } from "../../atoms/card/card"
import { ErrorMessage } from "../../atoms/error/error-message";
import { Row } from "../../atoms/row/row";
import { Title } from "../../atoms/text/title"
import { SuccessButton } from "../../molecules/buttons/success-button";

export const VerifyEmailForm = () => {

  const user = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState("");

  const onResendEmailClicked = async () => {
    setIsLoading(true);
    if (!user) {
      setError("Not logged in. Please try logging in again to resend verification email.");
      return;
    }
    try {
      await sendEmailVerification(user);
      setIsSent(true);
    } catch (error) {
      setError("Something went wrong while trying to send a verification email. Please try again later.")
    }
    setIsLoading(false);
  }

  return <Card maxWidth={30}>
    {!!error && <ErrorMessage message={error} />}
    <Title>Please verify your email.</Title>
    <p>A verification email has been sent to your inbox.</p>
    <p>Please click the link in the email to verify your account before continuing.</p>
    <Row margin="top">
      <p>After verifying your email, refresh the page or log out and log back in again to start using Firetrack.</p>
    </Row>
    <Row margin="top">
      <SuccessButton
        canSubmit={!isSent}
        isLoading={isLoading}
        onSubmit={onResendEmailClicked}
        text={isSent ? "Email sent" : "Resend email"}
        icon={faEnvelope}
      />
    </Row>
  </Card>
}
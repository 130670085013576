import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { Client } from "../../../../domain/model/client"
import { useModal } from "../../../../hooks/useModal"
import { DangerButton } from "../../../molecules/buttons/danger-button"
import { ConfirmationDialog } from "../../../organisms/dialogs/confirmation-dialog"

interface DeleteClientButtonProps {
    deleteClient: (client: Client) => Promise<void>,
    client: Client
}

export const DeleteClientButton: React.FC<DeleteClientButtonProps> = ({
    deleteClient,
    client
}) => {

    const modal = useModal();

    const innerOnClick = async () => {
        modal.show({
            title: `Delete client?`,
            children: <ConfirmationDialog 
                onCancel={modal.close}
                onConfirm={async () => {
                    await deleteClient(client);
                    modal.close();
                }}
                text={[`Are you sure you want to remove client '${client.name}'? This will also delete all projects for this client.`, `This action can not be undone.`]}
            />
        })
    }

    return <DangerButton
        canSubmit={true}
        isLoading={false}
        onSubmit={innerOnClick}
        text="Delete client"
        icon={faTrash}
        collapsible={true}
    />
}
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Client } from "../../../domain/model/client"
import { Project } from "../../../domain/model/project"
import { Row } from "../../atoms/row/row"
import { DeleteClientButton } from "../../organisms/buttons/client/delete-client-button"
import { EditClientButton } from "../../organisms/buttons/client/edit-client-button"
import { AddProjectButton } from "../../organisms/buttons/project/add-project-button"

interface ClientButtonsProps {
  client: Client,
  saveClient: (client: Client) => Promise<void>,
  deleteClient: (client: Client) => Promise<void>,
  saveProject: (project: Project) => Promise<void>
}

export const ClientButtons: React.FC<ClientButtonsProps> = ({
  client,
  saveClient,
  deleteClient,
  saveProject
}) => {

  return <Row margin="none" justifyContent="flex-end">
    <Row margin="none" className="client-buttons">
      <AddProjectButton client={client} onClick={saveProject} />
      <EditClientButton client={client} onClick={saveClient} />
      <DeleteClientButton client={client} deleteClient={deleteClient} />
    </Row>
    <Row margin="none" className="client-buttons-hint">
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </Row>
  </Row>

}
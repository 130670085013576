import { DateTime } from "luxon";
import validator from "valivalue";

export const validateTimeRecordDescription = (description: string | undefined): string => {
  if (!description) {
    return "";
  }

  return validator.strings.validateMaxLength(description, 128, "Description");
}

export const validateTimeRecordStop = (start: DateTime | undefined, stop: DateTime | undefined): DateTime => {
  validator.objects.validateNotNullOrUndefined(start, "Start");
  validator.objects.validateNotNullOrUndefined(stop, "End");
  validator.timestamps.validateIsAfter(stop!, start!, "End");
  return validator.timestamps.validateIsInPast(stop!, "End");
}

export const validateTimeRecordStart = (start: DateTime | undefined): DateTime => {
  validator.objects.validateNotNullOrUndefined(start, "Start");
  return validator.timestamps.validateIsInPast(start!, "Start");
}
import { v4 as uuidv4 } from "uuid";
import { validateClientName } from "../validators/client-validator";
import { validateUuid } from "../validators/uuid-validator";
import { uuid } from "./time-record";

export class Client {

  constructor(
    readonly id: uuid,
    readonly name: string,
  ) {
    validateUuid(id, "Client UUID");
    validateClientName(name);
  }

  static create(name: string): Client {
    return new Client(uuidv4(), name);
  }
}
import { validateProjectColor, validateProjectName } from "../validators/project-validator";
import { validateUuid } from "../validators/uuid-validator"
import { uuid } from "./time-record"
import { v4 as uuidv4 } from "uuid";

export class Project {
  constructor(
    readonly id: uuid,
    readonly name: string,
    readonly color: string,
    readonly clientId: string
  ) {
    validateUuid(id, "Project ID");
    validateProjectName(name);
    validateProjectColor(color);
    validateUuid(clientId, "Client ID");
  }

  static create(name: string, color: string, clientId: string) {
    return new Project(
      uuidv4(),
      name,
      color,
      clientId
    );
  }
}
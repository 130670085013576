import { EntryType, TableEntry } from "./table"
import "./table-row.css";

interface TableRowProps {
  entries: TableEntry[],
  className?: string
}

interface EntryProps {
  entry: TableEntry,
}

const ButtonEntry: React.FC<EntryProps> = ({
  entry
}) => {
  return <td className={`table-row-button-entry ${entry.className}`}>
    {entry.value}
  </td>
}

const NodeEntry: React.FC<EntryProps> = ({
  entry
}) => {
  return <td className={`table-row-node-entry ${entry.className}`}>
    {entry.value}
  </td>
}

const TextEntry: React.FC<EntryProps> = ({
  entry
}) => {

  const colSpan = entry.colSpan || 1;

  return <td
    className={entry.className}
    colSpan={colSpan}
    style={{
      textAlign: colSpan > 1
        ? "center"
        : "left"
    }}
  >
    {entry.value}
  </td>
}

export const TableRow: React.FC<TableRowProps> = ({
  entries
}) => {
  return <tr>
    {entries.map((entry, index) => {
      switch (entry.type) {
        case EntryType.BUTTON:
          return <ButtonEntry key={index} entry={entry} />
        case EntryType.NODE:
          return <NodeEntry key={index} entry={entry} />
        default:
          return <TextEntry key={index} entry={entry} />
      }
    })}
  </tr>
}
import { ReactNode, useState } from "react";
import { singletonHook } from "react-singleton-hook";

interface ShowModelParams {
  children: ReactNode,
  title: string
}

const useModalImplementation = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [children, setChildren] = useState<ReactNode>(undefined);
  const [title, setTitle] = useState("");

  const close = () => {
    setIsVisible(false);
    setChildren(undefined);
  }
  const show = ({
    children,
    title
  }: ShowModelParams) => {
    setChildren(children);
    setTitle(title);
    setIsVisible(true);
  }

  return {
    isVisible,
    close,
    show,
    children,
    title
  }
}

export const useModal = singletonHook({
  isVisible: false,
  close: () => {},
  show: () => {},
  children: undefined,
  title: ""
}, useModalImplementation);
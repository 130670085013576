import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Spinner } from "../../atoms/spinner/spinner"

import "./button.css";

interface ButtonProps {
  canSubmit: boolean,
  isLoading: boolean,
  text: string,
  loadingText?: string,
  icon?: IconDefinition,
  onSubmit: () => void | Promise<void>,
  className?: string,
  collapsible?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text,
  loadingText = "Loading",
  icon,
  className,
  collapsible = false
}) => {

  const innerClassName = collapsible
    ? `collapsible-button ${className}`
    : `non-collapsible-button ${className}`;

  return <button
    disabled={!canSubmit || isLoading}
    onClick={onSubmit}
    className={innerClassName}
    title={text}
  >
    {
      isLoading
        ? <Spinner />
        : icon && <FontAwesomeIcon icon={icon} />
    }
    {
      isLoading
        ? <span className={collapsible ? "collapsible-button-text" : ""}>{loadingText}</span>
        : <span className={collapsible ? "collapsible-button-text" : ""}>{text}</span>
    }

  </button>
}
import { InputLabel } from "../../atoms/input/label/input-label"
import { v4 as uuid } from "uuid";
import { useState } from "react";

import "./timestamp-input-group.css";
import { InputErrorMessage } from "../../atoms/input/error-message/input-error-message";
import { DateTime } from "luxon";
import { TimestampInput } from "../../atoms/input/timestamp/timestamp-input";

interface TimestampInputGroupProps {
    error?: string,
    label: string,
    isRequired: boolean,
    isError: boolean,
    onValueChange: (input: DateTime) => void,
    value?: DateTime
}

export const TimestampInputGroup: React.FC<TimestampInputGroupProps> = ({
    error,
    label,
    isRequired,
    isError,
    onValueChange,
    value
}) => {

    const [uniqueId] = useState(uuid());
    const id = `text-input-${uniqueId}`;

    return <div className="text-input-group">
        <InputLabel
            htmlFor={id}
            isError={isError}
            isRequired={isRequired}
        >
            {label}
        </InputLabel>
        <TimestampInput
            id={id}
            isError={isError}
            isRequired={isRequired}
            onValueChange={onValueChange}
            value={value}
        />
        {isError && <InputErrorMessage>{error}</InputErrorMessage>}
    </div>
}
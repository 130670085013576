import { DateTime } from "luxon";
import React from "react";
import { TimeRecord } from "../../../domain/model/time-record";
import { orderByDay } from "../../../domain/model/time-record-orderer";
import { formatDurationAsString } from "../../../helpers/duration-helper";
import { Row } from "../../atoms/row/row";
import { Title } from "../../atoms/text/title";
import { TimeRecordTable } from "../../molecules/time-tracking/time-record-table";

interface TimeRecordsByDayProps {
  timeRecords: TimeRecord[],
  startTracking: (record: TimeRecord) => Promise<void>,
  deleteTimeRecord: (record: TimeRecord) => Promise<void>,
  updateTimeRecord: (record: TimeRecord) => Promise<void>
}

export const TimeRecordsByDay: React.FC<TimeRecordsByDayProps> = ({
  timeRecords,
  startTracking,
  deleteTimeRecord,
  updateTimeRecord
}) => {

  const orderedByDay = orderByDay(timeRecords);

  const jsx = <>
    {orderedByDay.days.map(day => <div key={day}>
      <Title h={3}>{DateTime.fromISO(day).toLocaleString(DateTime.DATE_MED)} - {formatDurationAsString(TimeRecord.getTotalDuration(orderedByDay.recordsByDay.get(day)!))}</Title>
      <Row padding="around" margin="bottom">
        <TimeRecordTable
          startTracking={startTracking}
          deleteTimeRecord={deleteTimeRecord}
          updateTimeRecord={updateTimeRecord}
          timeRecords={orderedByDay.recordsByDay.get(day)!}
        />
      </Row>
      {/*<Row flexWrap="wrap">
        {orderedByDay.recordsByDay.get(day)!.map(record => <TimeRecordCard key={record.id} timeRecord={record} />)}
    </Row>*/}
    </div>)}
  </>;

  return !timeRecords.length
    ? <p className="margin-bottom-small">No time records yet.</p>
    : jsx
}
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { ErrorMessage } from "../../atoms/error/error-message"
import { Row } from "../../atoms/row/row"
import { DangerButton } from "../../molecules/buttons/danger-button"
import { SuccessButton } from "../../molecules/buttons/success-button"

import "./confirmation-dialog.css";

interface ConfirmationDialogProps {
    text: string | string[],
    onConfirm: () => void | Promise<void>,
    onCancel: () => void | Promise<void>,
    confirmationButtonText?: string,
    cancelButtonText?: string
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    text,
    onConfirm,
    confirmationButtonText = "Confirm",
    onCancel,
    cancelButtonText = "Cancel"
}) => {

    const [error, setError] = useState("");
    const [isConfirmLoading, setConfirmIsLoading] = useState(false);
    const [isCancelLoading, setCancelIsLoading] = useState(false);

    const innerOnConfirm = async () => {
        setConfirmIsLoading(true);
        setError("");
        try {
            await onConfirm();
        } catch (error) {
            setError((error as Error).message);
        }
        setConfirmIsLoading(false);
    }

    const innerOnCancel = async () => {
        setCancelIsLoading(true);
        setError("");
        try {
            await onCancel();
        } catch (error) {
            setError((error as Error).message);
        }
        setCancelIsLoading(false);
    }

    return <section className="confirmation-dialog">
        {!!error && <ErrorMessage message={error} />}
        {
            Array.isArray(text)
                ? text.map(message => <p key={message}>{message}</p>)
                : <p>{text}</p>
        }
        <Row margin="top" justifyContent="space-between">
            <SuccessButton
                canSubmit={!isCancelLoading}
                isLoading={isConfirmLoading}
                onSubmit={innerOnConfirm}
                text={confirmationButtonText}
                icon={faCheck}
            />
            <DangerButton
                canSubmit={!isConfirmLoading}
                isLoading={isCancelLoading}
                onSubmit={innerOnCancel}
                text={cancelButtonText}
                icon={faCheck}
            />
        </Row>
    </section>
}
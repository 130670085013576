import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { DateTime } from "luxon"
import { useState } from "react"
import { TimeRecord } from "../../domain/model/time-record"
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { useModal } from "../../hooks/useModal"
import { useTimeTrackingHistory } from "../../hooks/useTimeTrackingHistory"
import { Row } from "../atoms/row/row"
import { Title } from "../atoms/text/title"
import { SuccessButton } from "../molecules/buttons/success-button"
import { TimeRecordForm } from "../molecules/time-tracking/time-record-form"
import { PageContainer } from "../organisms/page-container/page-container"
import { TimeDistributionCard } from "../organisms/stats/last-week-distribution/time-distribution-card"
import { ActiveTimeTracking } from "../organisms/time-tracking/active-time-tracking"
import { TimeTrackingHistory } from "../organisms/time-tracking/time-tracking-history"

export const TimeTrackPage: React.FC = () => {

  const modal = useModal();
  const [lastUpdate, setLastUpdate] = useState(DateTime.now());
  const [recordToTrack, setTimeRecordToTrack] = useState<TimeRecord>();
  const timeTrackingHistory = useTimeTrackingHistory(lastUpdate);

  const onTimeRecordCreated = async (timeRecord: TimeRecord) => {
    await timeTrackingHistory.updateRecord(timeRecord);
    modal.close();
  }

  const onCreateRecordClicked = () => {
    modal.show({
      title: "Create time record",
      children: <TimeRecordForm updateTimeRecord={onTimeRecordCreated} record={TimeRecord.create("New time record")} />
    })
  }

  return <PageContainer
    authentication={AuthenticationRule.MustBeAuthenticated}
    title="Time tracking"
  >
    <Row margin="bottom">
      <Title margin={false}>Time tracking</Title>
      <SuccessButton canSubmit={true}
        isLoading={false}
        onSubmit={onCreateRecordClicked}
        text="Add time record"
        icon={faPlus}
      />
    </Row>

    <ActiveTimeTracking
      recordToTrack={recordToTrack}
      clearRecordToTrack={() => setTimeRecordToTrack(undefined)}
      onUpdate={() => setLastUpdate(DateTime.now())}
    />
    {!!timeTrackingHistory.hasRecords && <TimeDistributionCard
      lastUpdate={lastUpdate}
      timeTrackingHistory={timeTrackingHistory}
    />}
    <TimeTrackingHistory
      startTracking={async (record: TimeRecord) => setTimeRecordToTrack(record)}
      lastUpdate={lastUpdate}
      timeTrackingHistory={timeTrackingHistory}
    />
  </PageContainer>
}
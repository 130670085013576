import "./color-input.css";

interface ColorInputProps {
    onValueChange: (input: string) => void,
    value?: string
}

export const COLORS = [
    "#1BBC9C", 
    "#2ECD70", 
    "#3297DC", 
    "#9B59B6",
    "#34495E",
    "#17A085",
    "#27AE5F",
    "#2980B9",
    "#8E44AD",
    "#2C3E50",
    "#F1C40E",
    "#E67D23",
    "#E74C3C",
    "#ECF0F1",
    "#94A5A6",
    "#F39C12",
    "#D35400",
    "#C0392C",
    "#BDC3C7",
    "#7F8C8D"
]

export const ColorInput: React.FC<ColorInputProps> = ({
    onValueChange,
    value = COLORS[0]
}) => {

    return <div className="color-input">
        {
            COLORS.map(color => {
                let className = "color-input-value";
                if (color === value) {
                    className += " active";
                }
                return <div
                    className={className}
                    style={{ backgroundColor: color }}
                    onClick={() => onValueChange(color)}
                />
            })
        }
    </div>
}
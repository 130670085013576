import { faUser } from "@fortawesome/free-solid-svg-icons"
import { SuccessButton } from "./success-button"

interface RegisterButtonProps {
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean,
  isLoading: boolean,
  text?: string
}

export const RegisterButton: React.FC<RegisterButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text = "Register"
}) => {
  return <SuccessButton
    onSubmit={onSubmit}
    icon={faUser}
    text={text}
    canSubmit={canSubmit}
    isLoading={isLoading}
  />
}
import { TodoColumn } from "../../../../domain/model/todo-column";
import { TodoColumnSection } from "./todo-column-section";
import { TodoManager } from "../../../../hooks/useTodoManager";

interface TodoColumnsSectionProps {
  columns: TodoColumn[],
  todoManager: TodoManager
}

export const TodoColumnsSection: React.FC<TodoColumnsSectionProps> = ({
  columns,
  todoManager
}) => {

  return <>
    {columns.map((column, index) => (
      <TodoColumnSection
        key={column.id}
        column={column}
        todoManager={todoManager}
      />
    ))}
  </>
}
import validator from "valivalue"

export const validateEmail = (email: string | undefined) => {
    validator.objects.validateNotNullOrUndefined(email, "Email");
    validator.strings.validateMinAndMaxLength(email!, 2, 128, "Email");
    return validator.strings.validateResemblesEmail(email!, "Email");
}

export const validatePassword = (password: string | undefined) => {
    validator.objects.validateNotNullOrUndefined(password, "Password");
    return validator.strings.validateMinAndMaxLength(password!, 16, 128, "Password");
}

export const validatePasswordConfirmation = (password: string | undefined, passwordConfirmation: string | undefined) => {
    validator.strings.validateEquals(password || "", passwordConfirmation || "", "Password confirmation", () => "Password confirmation does not match password.");
    return passwordConfirmation || "";
}

export const validateDisplayName = (displayName: string | undefined) => {
    validator.objects.validateNotNullOrUndefined(displayName, "Display name");
    return validator.strings.validateMinAndMaxLength(displayName!, 2, 25, "Display name");
}
import validator from "valivalue";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { TodoItem } from "./todo-item";
import { validateTodoColumnTitle } from "../validators/todo-column-validators";
import { validateUuid } from "../validators/uuid-validator";

export class TodoColumn {

  constructor(
    readonly id: string,
    readonly title: string,
    readonly creationTimestamp: DateTime,
    readonly items: TodoItem[] = [],
    readonly isHidden = false
  ) {
    validateUuid(id);
    validateTodoColumnTitle(title);
    validator.objects.validateNotNullOrUndefined(creationTimestamp, "Todo column creation timestamp");
    validator.timestamps.validateIsInPast(creationTimestamp, "Todo column creation timestamp");
    validator.objects.validateNotNullOrUndefined(items, "Todo column items");
  }

  private findIndex(todoItem: TodoItem) {
    return this.items.findIndex(item => item.id === todoItem.id);
  }

  updateItem(todoItem: TodoItem) {
    const foundIndex = this.findIndex(todoItem);
    if (foundIndex >= 0) {
      this.items[foundIndex] = todoItem;
    }
  }

  removeItem(todoItem: TodoItem) {
    const foundIndex = this.findIndex(todoItem);
    if (foundIndex >= 0) {
      this.items.splice(foundIndex, 1);
    }
  }

  moveItemUpOrDown(todoItem: TodoItem, direction: "up" | "down") {
    const foundIndex = this.items.findIndex(item => item.id === todoItem.id);

    if (foundIndex >= 0) {
      if (direction === "down" && foundIndex < this.items.length - 1) {
        this.items.splice(foundIndex, 1);
        this.items.splice(foundIndex + 1, 0, todoItem);
      }
      if (direction === "up" && foundIndex > 0) {
        this.items.splice(foundIndex, 1);
        this.items.splice(foundIndex - 1, 0, todoItem);
      }
    }
  }

  clone() {
    return new TodoColumn(
      this.id,
      this.title,
      this.creationTimestamp,
      this.items.map(item => item.clone()),
      this.isHidden
    );
  }

  static create(title: string, todoItems: TodoItem[] = []) {
    return new TodoColumn(
      uuidv4(),
      title,
      DateTime.now(),
      todoItems
    );
  }
}
import { DateTime } from "luxon";
import { useCustomInput, useSubmitButton, useTextInput } from "morfhp";
import { useState } from "react";
import { TimeRecord } from "../../../domain/model/time-record"
import { validateTimeRecordDescription, validateTimeRecordStart, validateTimeRecordStop } from "../../../domain/validators/time-record-validators";
import { ErrorMessage } from "../../atoms/error/error-message";
import { ProjectInput } from "../../atoms/input/project/project-input";
import { UpdateRecordButton } from "../../organisms/buttons/time-tracking/update-record-button";
import { TextInputGroup } from "../input/text-input-group";
import { TimestampInputGroup } from "../input/timestamp-input-group";

interface TimeRecordFormProps {
    record: TimeRecord,
    updateTimeRecord: (record: TimeRecord) => Promise<void>
}

export const TimeRecordForm: React.FC<TimeRecordFormProps> = ({
    record,
    updateTimeRecord
}) => {

    const [error, setError] = useState("");

    const descriptionInput = useTextInput({
        isRequired: false,
        label: "Description",
        Component: TextInputGroup,
        validator: validateTimeRecordDescription,
        defaultValue: record.description
    });

    const startInput = useCustomInput<DateTime>({
        isRequired: true,
        label: "Start",
        defaultValue: record.start,
        Component: TimestampInputGroup,
        validator: validateTimeRecordStart,
        emptyValueMessage: "Please enter a valid start timestamp.",
        validateInitially: true
    });

    const stopInput = useCustomInput<DateTime>({
        isRequired: true,
        label: "End",
        defaultValue: record.end!,
        Component: TimestampInputGroup,
        validator: (stop: DateTime | undefined) => validateTimeRecordStop(startInput.formValue.value, stop),
        emptyValueMessage: "Please enter a valid start timestamp.",
        validateInitially: true
    });

    const projectInput = useCustomInput<string | undefined>({
        isRequired: false,
        label: "Select project",
        defaultValue: record.projectId,
        Component: ProjectInput,
        validator: (projectId) => projectId,
    })

    const updateButton = useSubmitButton({
        inputs: [startInput, descriptionInput, stopInput, projectInput],
        Component: UpdateRecordButton,
        onSubmit: onUpdateButtonClick
    });

    async function onUpdateButtonClick() {
        try {

            const description = descriptionInput.formValue.value!;
            const start = startInput.formValue.value!;
            const end = stopInput.formValue.value!;
            const projectId = projectInput.formValue.value;

            const updatedRecord = new TimeRecord(record.id, description, start, end, projectId);

            await updateTimeRecord(updatedRecord);
        } catch (error) {
            setError((error as Error).message);
        }
    }

    return <>
        {!!error && <ErrorMessage message={error} />}
        {descriptionInput.jsx}
        {startInput.jsx}
        {stopInput.jsx}
        {projectInput.jsx}
        {updateButton.jsx}
    </>
}
import "./color.css";

interface ColorProps {
    color: string
}

export const Color: React.FC<ColorProps> = ({
    color
}) => {
    return <div className="color-circle" style={{ backgroundColor: color }} />
}
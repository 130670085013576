import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import validator from "valivalue";
import { validateTodoBoardTitle } from "../validators/todo-board-validators";
import { validateUuid } from "../validators/uuid-validator";
import { TodoColumn } from "./todo-column";
import { TodoItem } from "./todo-item";

export class TodoBoard {

  constructor(
    readonly id: string,
    readonly title: string,
    readonly creationTimestamp: DateTime,
    readonly columns: TodoColumn[] = [],
    readonly isHidden = false
  ) {
    validateUuid(id);
    validateTodoBoardTitle(title);
    validator.objects.validateNotNullOrUndefined(creationTimestamp, "Todo board creation timestamp");
    validator.timestamps.validateIsInPast(creationTimestamp, "Todo board creation timestamp");
    validator.objects.validateNotNullOrUndefined(columns, "Todo board columns");
  }

  updateItem(todoItem: TodoItem) {
    for (const column of this.columns) {
      column.updateItem(todoItem);
    }
  }

  updateColumn(todoColumn: TodoColumn) {
    const foundIndex = this.columns.findIndex(column => column.id === todoColumn.id);
    if (foundIndex >= 0) {
      this.columns[foundIndex] = todoColumn;
    }
  }

  addTodoItem(todoItem: TodoItem, targetColumn: TodoColumn) {
    for (const column of this.columns) {
      if (column.id === targetColumn.id) {
        column.items.push(todoItem);
      }
    }
  }

  moveColumn(todoColumn: TodoColumn, direction: "left" | "right") {
    const foundIndex = this.columns.findIndex(column => column.id === todoColumn.id);
    if (foundIndex >= 0) {
      if (direction === "left" && foundIndex > 0) {
        this.removeColumn(todoColumn);
        this.columns.splice(foundIndex - 1, 0, todoColumn);
      }
      if (direction === "right" && foundIndex < this.columns.length - 1) {
        this.removeColumn(todoColumn);
        this.columns.splice(foundIndex + 1, 0, todoColumn);
      }
    }
  }

  moveItem(todoItem: TodoItem, direction: "left" | "right" | "up" | "down") {
    if (direction === "up" || direction === "down") {
      this.moveItemUpOrDown(todoItem, direction);
    } else if (direction === "left" || direction === "right") {
      this.moveItemLeftOrRight(todoItem, direction);
    }
  }

  private moveItemUpOrDown(todoItem: TodoItem, direction: "up" | "down") {
    for (const column of this.columns) {
      column.moveItemUpOrDown(todoItem, direction);
    }
  }

  private moveItemLeftOrRight(todoItem: TodoItem, direction: "left" | "right") {
    for (let colIndex = 0; colIndex < this.columns.length; colIndex++) {
      const foundIndex = this.columns[colIndex].items.findIndex(item => todoItem.id === item.id);
      if (foundIndex >= 0) {
        if (direction === "left" && colIndex > 0) {
          this.columns[colIndex].removeItem(todoItem);
          this.columns[colIndex - 1].items.push(todoItem);
          return;
        }
        if (direction === "right" && colIndex < this.columns.length - 1) {
          this.columns[colIndex].removeItem(todoItem);
          this.columns[colIndex + 1].items.push(todoItem);
          return;
        }
      }
    }
  }

  removeTodoItem(todoItem: TodoItem) {
    for (const column of this.columns) {
      column.removeItem(todoItem);
    }
  }

  removeColumn(todoColumn: TodoColumn) {
    const foundIndex = this.columns.findIndex(column => column.id === todoColumn.id);
    if (foundIndex >= 0) {
      this.columns.splice(foundIndex, 1);
    }
  }

  clone() {
    return new TodoBoard(
      this.id,
      this.title,
      this.creationTimestamp,
      this.columns.map(column => column.clone()),
      this.isHidden
    )
  }

  static create(title: string, todoColumns: TodoColumn[] = []) {

    const defaultColumns = todoColumns.length
      ? todoColumns
      : [
        TodoColumn.create("To do"),
        TodoColumn.create("Doing"),
        TodoColumn.create("Done")
      ];

    return new TodoBoard(
      uuidv4(),
      title,
      DateTime.now(),
      defaultColumns
    );
  }
}
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { Row } from "../../row/row"
import {TextInput } from "./text-input";

import "./filter-input.css";

interface FilterInputProps {
  onValueChange: (value: string) => void,
  value: string
}

export const FilterInput: React.FC<FilterInputProps> = ({
  onValueChange,
  value
}) => {

  const id = useMemo(() => {
    return "" + Math.floor(Math.random() * 1000);
  }, []);

  return <Row className="filter-input" margin="bottom">
    <TextInput 
      id={id}
      isError={false}
      onValueChange={onValueChange}
      value={value}
      isRequired={false}
      placeholder="filter"
    />
    <FontAwesomeIcon className="filter-input-clear-button" icon={faXmark} onClick={() => onValueChange("")}/>
  </Row>
}
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { WarningButton } from "../../../molecules/buttons/warning-button";

interface EditProjectButtonProps {
  onClick: () => void | Promise<void>,
  showText?: boolean
}

export const EditProjectButton: React.FC<EditProjectButtonProps> = ({
  onClick,
  showText = false
}) => {

  return <WarningButton
    canSubmit={true}
    isLoading={false}
    text={"Edit"}
    collapsible={!showText}
    icon={faPencil}
    onSubmit={onClick}
  />

}
import { NavigationItem } from "../../atoms/navigation/navigation-item";
import { faRightFromBracket, faUser, faHome, faStopwatch, faList, faDiagramProject, faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "./navigation-bar.css";
import { useAuthentication } from "../../../firebase/useAuthentication";
import { NavigationDivider } from "../../atoms/navigation/navigation-divider";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "../../atoms/text/title";
import { Row } from "../../atoms/row/row";

export const NavigationBar = () => {

    const user = useAuthentication();
    const isLoggedIn = user && user.emailVerified;
    const isLoggedInWithoutVerification = user && !user.emailVerified;
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (window.innerWidth <= 500) {
            setIsVisible(false);
        }
    }, []);

    return <nav className={`nav-container-${isVisible ? "visible" : "hidden"}`}>
        {
            !!isVisible && <div>
                <Row margin="none">
                    <Title>Firetrack</Title>
                </Row>
                <ul>
                    <NavigationItem icon={faHome} text="Home" route={!!user ? "/overview" : "/"} />
                    {isLoggedIn && <NavigationItem icon={faStopwatch} text="Time tracking" route="/timetrack" />}
                    {isLoggedIn && <NavigationItem icon={faList} text="To do" route="/todo" />}
                    {isLoggedIn && <NavigationItem icon={faDiagramProject} text="Clients & Projects" route="/clients" />}

                    <NavigationDivider />
                    {isLoggedIn && <NavigationItem icon={faUser} text="Profile" route="/profile" />}
                    {
                        !!isLoggedInWithoutVerification
                            ? <NavigationItem icon={faRightFromBracket} text="Log out" route="/logout" />
                            : isLoggedIn
                                ? <NavigationItem icon={faRightFromBracket} text="Log out" route="/logout" />
                                : <NavigationItem icon={faUser} text="Log in" route="/login" />
                    }
                </ul>
            </div>
        }
        <div className={`nav-collapse-button nav-collapse-button-${isVisible ? "visible" : "hidden"}`} onClick={() => setIsVisible(!isVisible)}>
            <FontAwesomeIcon
                icon={isVisible ? faCaretLeft : faCaretRight}
            />
        </div>
    </nav >
}
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, User, sendEmailVerification } from "firebase/auth";

export const isAuthenticated = () => {
  const auth = getAuth();
  return !!auth.currentUser;
}

export const createUser = async (email: string, password: string) => {
  const auth = getAuth();
  const result = await createUserWithEmailAndPassword(auth, email, password);
  sendEmailVerification(result.user);
  return result;
}

export const loginUser = async (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export const updateDisplayName = async (user: User, displayName: string) => {
  return updateProfile(user, { displayName });
}
import { Client } from "../../../../domain/model/client";
import { useModal } from "../../../../hooks/useModal";
import { ProjectForm } from "../../clients/project-form";
import { Project } from "../../../../domain/model/project";
import { AddButton } from "../../../molecules/buttons/add-button";

interface AddProjectButtonProps {
  client: Client,
  onClick: (project: Project) => Promise<void>
}

export const AddProjectButton: React.FC<AddProjectButtonProps> = ({
  client,
  onClick
}) => {

  const modal = useModal();

  const innerOnclick = async (project: Project) => {
    await onClick(project);
    modal.close();
  }

  const showModal = async () => {
    modal.show({
      title: `Add project`,
      children: <ProjectForm
        client={client}
        onSubmit={innerOnclick}
      />
    })
  };

  return <AddButton
    onClick={showModal}
    text="Add project"
  />
}
import { DocumentData, DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { DateTime } from "luxon";
import { TodoBoard } from "../model/todo-board";
import { TodoColumn } from "../model/todo-column";
import { TodoItem } from "../model/todo-item";

const itemToFirestore = (todoItem: TodoItem) => {
  return {
    id: todoItem.id,
    description: todoItem.description,
    creationTimstamp: todoItem.creationTimestamp.toISO({ includeOffset: false }),
    projectId: todoItem.projectId || ""
  };
}

const columnToFirestore = (column: TodoColumn) => {
  const items = column.items.map(itemToFirestore);
  return {
    id: column.id,
    title: column.title,
    creationTimestamp: column.creationTimestamp.toISO({ includeOffset: false }),
    items,
    isHidden: column.isHidden
  }
}

const boardToFirestore = (board: TodoBoard) => {
  const columns = board.columns.map(columnToFirestore);
  return {
    id: board.id,
    title: board.title,
    creationTimestamp: board.creationTimestamp.toISO({ includeOffset: false }),
    columns,
    isHidden: board.isHidden
  }
}

const toFirestore = (boards: TodoBoard[]) => {
  return { boards: boards.map(boardToFirestore) }
}

const itemFromFirestore = (item: any) => {
  const {
    id,
    description,
    projectId,
    creationTimestamp
  } = item;

  return new TodoItem(id, description, DateTime.fromISO(creationTimestamp), projectId);
}

const columnFromFirestore = (column: any) => {
  const {
    id,
    title,
    items,
    creationTimestamp,
    isHidden
  } = column;

  return new TodoColumn(
    id,
    title,
    DateTime.fromISO(creationTimestamp),
    items.map(itemFromFirestore),
    isHidden
  );
}

const boardFromFirestore = (board: any) => {
  const {
    id,
    title,
    columns,
    creationTimestamp,
    isHidden
  } = board;

  return new TodoBoard(
    id,
    title,
    DateTime.fromISO(creationTimestamp),
    columns.map(columnFromFirestore),
    isHidden
  );
}

const fromFirestore = (snapshot: DocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
  const data = snapshot.data(options);
  if (!data) {
    throw new Error("No data found.");
  }

  return data.boards.map(boardFromFirestore);
}

export const TodoConverter = {
  toFirestore,
  fromFirestore
};
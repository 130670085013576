import { InputLabel } from "../../atoms/input/label/input-label"
import { TextInput } from "../../atoms/input/text/text-input"
import { v4 as uuid } from "uuid";
import { useState } from "react";

import "./text-input-group.css";
import { InputErrorMessage } from "../../atoms/input/error-message/input-error-message";

interface TextInputGroupProps {
    error?: string,
    label: string,
    isRequired: boolean,
    isError: boolean,
    onValueChange: (input: string) => void,
    secureTextEntry?: boolean,
    value?: string,
    placeholder?: string
}

export const TextInputGroup: React.FC<TextInputGroupProps> = ({
    error,
    label,
    isRequired,
    isError,
    onValueChange,
    secureTextEntry = false,
    value,
    placeholder
}) => {

    const [uniqueId] = useState(uuid());
    const id = `text-input-${uniqueId}`;

    return <div className="text-input-group">
        <InputLabel
            htmlFor={id}
            isError={isError}
            isRequired={isRequired}
        >
            {label}
        </InputLabel>
        <TextInput
            id={id}
            isError={isError}
            isRequired={isRequired}
            onValueChange={onValueChange}
            secureTextEntry={secureTextEntry}
            value={value}
            placeholder={placeholder}
        />
        {isError && <InputErrorMessage>{error}</InputErrorMessage>}
    </div>
}
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { DropdownMenuItem } from "./dropdown-menu-item";

import "./dropdown-menu.css";

export interface DropdownMenuEntry {
  onClick: () => void | Promise<void>,
  icon: IconDefinition,
  text: string
}

interface DropdownMenuProps {
  entries: DropdownMenuEntry[]
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  entries
}) => {

  return <div className="dropdown-menu-container">
    {entries.map((entry, index) => <DropdownMenuItem key={index} entry={entry} />)}
  </div>
}
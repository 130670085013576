import { faCaretDown, faCaretLeft, faCaretRight, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { TodoItem } from "../../../domain/model/todo-item";
import { TodoManager } from "../../../hooks/useTodoManager"
import { DropdownMenu } from "../../atoms/dropdown/dropdown-menu";

interface TodoItemDropdownProps {
  todoManager: TodoManager,
  todoItem: TodoItem
}

export const TodoItemDropdown: React.FC<TodoItemDropdownProps> = ({
  todoManager,
  todoItem
}) => {

  const onMoveLeftButtonClicked = async () => {
    await todoManager.moveTodoItem(todoItem, "left");
  }

  const onMoveRightButtonClicked = async () => {
    await todoManager.moveTodoItem(todoItem, "right");
  }

  const onMoveUpButtonClicked = async () => {
    await todoManager.moveTodoItem(todoItem, "up");
  }

  const onMoveDownButtonClicked = async () => {
    await todoManager.moveTodoItem(todoItem, "down");
  }

  const entries = [
    { text: "Move up", icon: faCaretUp, onClick: onMoveUpButtonClicked},
    { text: "Move left", icon: faCaretLeft, onClick: onMoveLeftButtonClicked },
    { text: "Move right", icon: faCaretRight, onClick: onMoveRightButtonClicked },
    { text: "Move down", icon: faCaretDown, onClick: onMoveDownButtonClicked},
  ]

  return <DropdownMenu
    entries={entries}
  />
}
import { Duration } from "luxon"
import { Project } from "../../../../domain/model/project"
import { formatDurationAsString } from "../../../../helpers/duration-helper";
import { KeyValueList } from "../../../atoms/text/key-value-list";

import "./duration-block.css";

interface DurationBlockProps {
  project?: Project,
  duration: Duration,
  percentage: number
}

export const DurationBlock: React.FC<DurationBlockProps> = ({
  project,
  duration,
  percentage
}) => {

  const color = project
    ? project.color
    : "gray";

  const durationText = formatDurationAsString(duration);

  const keyValues = [
    { property: "Project", value: project?.name || "No project" },
    { property: "Duration", value: durationText},
    { property: "Percentage", value: `${percentage}%` }
  ];

  return <div className="duration-block" style={{
    width: `${percentage}%`,
    backgroundColor: color
  }}>
    <p>{percentage}%</p>
    <div className="duration-block-hover-card">
      <KeyValueList keyValues={keyValues} wrapValues={false} />
    </div>
  </div>
}
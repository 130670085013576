import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { TodoBoard } from "../../domain/model/todo-board";
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { useModal } from "../../hooks/useModal";
import { useTodoManager } from "../../hooks/useTodoManager"
import { ErrorMessage } from "../atoms/error/error-message";
import { Row } from "../atoms/row/row";
import { Title } from "../atoms/text/title";
import { SuccessButton } from "../molecules/buttons/success-button";
import { PageContainer } from "../organisms/page-container/page-container"
import { TodoBoardForm } from "../organisms/todo/board/todo-board-form";
import { TodoBoardSection } from "../organisms/todo/board/todo-board-section";

export const TodoPage = () => {

  const todoManager = useTodoManager();
  const modal = useModal();

  const onCreateBoardSubmit = async (todoBoard: TodoBoard) => {
    await todoManager.addTodoBoard(todoBoard);
    modal.close();
  }

  const onCreateBoardClicked = () => {
    modal.show({
      title: "Create board",
      children: <TodoBoardForm onSubmit={onCreateBoardSubmit} />
    })
  }

  return <PageContainer
    title="To do"
    authentication={AuthenticationRule.MustBeAuthenticated}
  >
    {!!todoManager.error && <ErrorMessage message={todoManager.error} />}
    <Row margin="bottom">
      <Title margin={false}>To do</Title>
      <SuccessButton canSubmit={true}
        isLoading={false}
        onSubmit={onCreateBoardClicked}
        text="Add board"
        icon={faPlus}
      />
    </Row>
    {
      !todoManager.todoBoards.length
        ? <p>No boards yet.</p>
        : todoManager.todoBoards.map(board => {
          return <TodoBoardSection
            todoManager={todoManager}
            key={board.id}
            board={board}
          />
        })
    }
  </PageContainer>
}
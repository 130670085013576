import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Title } from "../atoms/text/title"
import { PageContainer } from "../organisms/page-container/page-container"
import { ProfileDetailsCard } from "../organisms/profile/profile-details-card"

export const ProfilePage = () => {
    return <PageContainer
        title="Edit user details"
        authentication={AuthenticationRule.MustBeAuthenticated}
    >
        <Title>Your profile</Title>
        <ProfileDetailsCard />
    </PageContainer>
}
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { Project } from "../../../../domain/model/project";
import { useModal } from "../../../../hooks/useModal";
import { DangerButton } from "../../../molecules/buttons/danger-button";
import { ConfirmationDialog } from "../../dialogs/confirmation-dialog";

interface DeleteProjectButtonProps {
    deleteProject: (project: Project) => Promise<void>,
    project: Project,
    showText?: boolean
}

export const DeleteProjectButton: React.FC<DeleteProjectButtonProps> = ({
    deleteProject,
    project,
    showText = false
}) => {

    const modal = useModal();

    const innerOnClick = async () => {
        modal.show({
            title: `Delete project?`,
            children: <ConfirmationDialog
                onCancel={modal.close}
                onConfirm={async () => {
                    await deleteProject(project);
                    modal.close();
                }}
                text={[`Are you sure you want to remove project '${project.name}'?`, `This action can not be undone.`]}
            />
        })
    }

    return <DangerButton
        canSubmit={true}
        isLoading={false}
        onSubmit={innerOnClick}
        text={"Delete project"}
        collapsible={!showText}
        icon={faTrash}
    />
}
import { useTextInput, useSubmitButton } from "morfhp"
import { validateEmail, validatePassword } from "../../../domain/validators/auth-validators";
import { LoginButton } from "../../molecules/buttons/login-button";
import { Card } from "../../atoms/card/card"
import { Title } from "../../atoms/text/title";
import { TextInputGroup } from "../../molecules/input/text-input-group"
import { Row } from "../../atoms/row/row";
import { RedirectButton } from "../../molecules/buttons/redirect-button";
import { loginUser } from "../../../firebase/firebase-auth";
import { useState } from "react";
import { ErrorMessage } from "../../atoms/error/error-message";
import { mapError } from "../../../firebase/firebase-error-mapper";
import { Link, useNavigate } from "react-router-dom";
import "./login-form.css";

export const LoginForm: React.FC = () => {

    const navigate = useNavigate();
    const [error, setError] = useState("");

    const emailInput = useTextInput({
        isRequired: true,
        label: "Email",
        Component: TextInputGroup,
        validator: validateEmail
    });

    const passwordInput = useTextInput({
        isRequired: true,
        label: "Password",
        Component: TextInputGroup,
        validator: validatePassword,
        secureTextEntry: true
    });

    const submitButton = useSubmitButton({
        inputs: [emailInput, passwordInput],
        Component: LoginButton,
        onSubmit: onLogin
    });

    async function onLogin() {
        try {
            const email = emailInput.formValue.value!;
            const password = passwordInput.formValue.value!;
            await loginUser(email, password);
            navigate("/overview");
        } catch (error) {
            const message = mapError(error);
            setError(message);
        }
    }

    return <Card>
        <Title>Log in</Title>
        {!!error && <ErrorMessage message={error} />}
        {emailInput.jsx}
        {passwordInput.jsx}
        <Row justifyContent="right">
            <Link className="reset-password-link" to="/reset-password">Reset password</Link>
        </Row>
        <Row justifyContent="space-between" margin="top">
            {submitButton.jsx}
            <RedirectButton
                route="/register"
                text="Don't have an account yet?"
            />
        </Row>
    </Card>
}
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateDisplayName } from "../../../firebase/firebase-auth";
import { useAuthentication } from "../../../firebase/useAuthentication"
import { useModal } from "../../../hooks/useModal";
import { Card } from "../../atoms/card/card"
import { Row } from "../../atoms/row/row";
import { KeyValueList } from "../../atoms/text/key-value-list";
import { Title } from "../../atoms/text/title"
import { ProfileDetailsForm } from "./profile-details-form";

export const ProfileDetailsCard: React.FC = () => {

    const user = useAuthentication();

    const modal = useModal();

    const onUpdateDisplayName = async (displayName: string) => {
        if (user) {
            await updateDisplayName(user, displayName);
            modal.close();
        }
    }

    const onEditProfileClick = () => {
        modal.show({
            title: "Edit profile details",
            children: <ProfileDetailsForm
                name={user?.displayName}
                updateDisplayName={onUpdateDisplayName}
            />
        })
    }

    const userDetails = [
        { property: "Email", value: user?.email || "No email" },
        { property: "Display name", value: user?.displayName || "No display name" },
    ];

    return <Row>
        <Card>
            <Row justifyContent="space-between" alignItems="center" margin="bottom">
                <Title h={3} margin={false}>Personal details</Title>
                <FontAwesomeIcon
                    icon={faPencil}
                    size="xs"
                    onClick={onEditProfileClick}
                    cursor="pointer"
                />
            </Row>
            <KeyValueList keyValues={userDetails} />
        </Card>
    </Row>
}
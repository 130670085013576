import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { TodoItem } from "../../../../domain/model/todo-item"
import { useDropdown } from "../../../../hooks/useDropdown";
import { useSingleProjectManager } from "../../../../hooks/useSingleProjectManager"
import { TodoManager } from "../../../../hooks/useTodoManager";
import { Color } from "../../../atoms/color/color"
import { Row } from "../../../atoms/row/row"
import { TodoItemDropdown } from "../../../molecules/todo/todo-item-dropdown";
import { TodoItemButtons } from "./todo-item-buttons";

import "./todo-item-card.css";

interface TodoItemCardProps {
  item: TodoItem,
  todoManager: TodoManager
}

export const TodoItemCard: React.FC<TodoItemCardProps> = ({
  item,
  todoManager
}) => {

  const projectManager = useSingleProjectManager(item.projectId);
  const ref = useRef(null);
  const dropdown = useDropdown();

  const onEditClick = () => {
    if (!dropdown.isVisible) {
      dropdown.show({
        children: <TodoItemDropdown todoItem={item} todoManager={todoManager} />,
        parent: ref
      })
    } else {
      dropdown.close();
    }
  }

  return <section
    className="todo-item-card"
  >
    <Row margin="bottom" justifyContent="space-between">
      {
        !!item.projectId && projectManager.project
          ? <Row>
            <Color color={projectManager.project.color} />
            <p>{projectManager.project.name} </p>
          </Row>
          : <p className="no-project">Not assigned to project</p>
      }
      <p className="edit-todo-item" ref={ref} onClick={onEditClick}>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </p>
    </Row>
    <p>{item.description}</p>
    <TodoItemButtons
      item={item}
      todoManager={todoManager}
    />
  </section>
}
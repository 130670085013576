import { useCallback, useEffect, useState } from "react";
import { Client } from "../domain/model/client";
import { ClientService } from "../domain/service/client-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";
import { Logger } from "../logging/logger";

export const useClientsManager = () => {

  const user = useMustBeAuthenticated();

  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const saveClient = useCallback(async (client: Client) => {
    if (user) {
      Logger.debug(`Adding new client for user with id '${user.uid}'.`);
      await ClientService.getInstance().saveClient(user.uid, client);
      const clients = await ClientService.getInstance().getClients(user.uid);
      setClients(clients);
    } else {
      Logger.debug("No user, could not add client.");
    }
  }, [user])

  const getClients = useCallback(async () => {
    setIsLoading(true);
    try {
      if (user) {
        Logger.debug(`Getting clients for user ${user.uid}`);
        const clients = await ClientService.getInstance().getClients(user.uid);
        setClients(clients);
      } else {
        Logger.debug(`No user, could not get clients.`);
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user])

  const deleteClient = useCallback(async (client: Client) => {
    setIsLoading(true);
    try {
      if (user) {
        Logger.debug(`Removing client with id "${client.id}".`);
        await ClientService.getInstance().deleteClient(user.uid, client);
        const clients = await ClientService.getInstance().getClients(user.uid);
        setClients(clients);
      } else {
        Logger.debug("No user, could not remove client");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user])

  useEffect(() => {
    getClients();
  }, [getClients]);

  return {
    clients,
    isLoading,
    error,
    saveClient,
    deleteClient
  };
}
import { SuccessButton } from "./success-button"
import { faPencil } from "@fortawesome/free-solid-svg-icons";

interface SaveButtonProps {
  canSubmit: boolean,
  isLoading: boolean,
  onSubmit: () => void | Promise<void>
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  canSubmit,
  isLoading,
  onSubmit
}) => {
  return <SuccessButton
    canSubmit={canSubmit}
    isLoading={isLoading}
    onSubmit={onSubmit}
    text="Save"
    icon={faPencil}
  />
}
import { faCaretLeft, faCaretRight, faEye, faEyeSlash, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TodoColumn } from "../../../domain/model/todo-column";
import { useModal } from "../../../hooks/useModal";
import { TodoManager } from "../../../hooks/useTodoManager";
import { DropdownMenu } from "../../atoms/dropdown/dropdown-menu";
import { ConfirmationDialog } from "../../organisms/dialogs/confirmation-dialog";
import { TodoColumnForm } from "../../organisms/todo/column/todo-column-form";

interface TodoColumnDropdownProps {
  column: TodoColumn,
  todoManager: TodoManager
}

export const TodoColumnDropdown: React.FC<TodoColumnDropdownProps> = ({
  column,
  todoManager
}) => {

  const modal = useModal();
  const onEditButtonSubmit = async (column: TodoColumn) => {
    await todoManager.updateTodoColumn(column);
    modal.close();
  }

  const onEditButtonClick = () => {
    modal.show({
      title: "Edit column",
      children: <TodoColumnForm column={column} onSubmit={onEditButtonSubmit} />
    });
  }

  const onDeleteButtonSubmit = async () => {
    await todoManager.removeTodoColumn(column);
    modal.close();
  }

  const onDeleteButtonClicked = () => {
    modal.show({
      title: "Delete column",
      children: <ConfirmationDialog
        text={[
          "Are you sure you want to delete this column?",
          "This action can not be undone."
        ]}
        onConfirm={onDeleteButtonSubmit}
        onCancel={modal.close}
      />
    });
  }

  const onMoveLeftButtonClicked = async () => {
    await todoManager.moveTodoColumn(column, "left");
  }

  const onMoveRightButtonClicked = async () => {
    await todoManager.moveTodoColumn(column, "right");
  }

  const onHideColumnSubmit = async () => {
    const updatedColumn = new TodoColumn(column.id, column.title, column.creationTimestamp, column.items, !column.isHidden);
    await todoManager.updateTodoColumn(updatedColumn);
  }

  const entries = [
    { text: column.isHidden ? "Show" : "Hide", icon: column.isHidden ? faEye : faEyeSlash, onClick: onHideColumnSubmit },
    { text: "Edit", icon: faPencil, onClick: onEditButtonClick },
    { text: "Delete", icon: faTrash, onClick: onDeleteButtonClicked },
    { text: "Move left", icon: faCaretLeft, onClick: onMoveLeftButtonClicked },
    { text: "Move right", icon: faCaretRight, onClick: onMoveRightButtonClicked },
  ]

  return <DropdownMenu
    entries={entries}
  />
}
import { useState } from "react"
import { ErrorMessage } from "../../atoms/error/error-message";
import { Spinner } from "../../atoms/spinner/spinner";

import "./load-more-records.css";

interface LoadMoreItems {
    getNext: () => Promise<boolean>,
    hasNewItems: boolean
}

export const LoadMoreRecords: React.FC<LoadMoreItems> = ({
    getNext,
    hasNewItems
}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const onGetNext = async () => {
        setIsLoading(true);
        try {
            await getNext();
        } catch (error) {
            setError((error as Error).message);
        }
        setIsLoading(false);
    }

    return <div className="load-more-records">
        {
            isLoading
                ? <Spinner />
                : !!error
                    ? <ErrorMessage message={error} />
                    : hasNewItems
                        ? <button onClick={onGetNext}>Load more items.</button>
                        : <p>No more records.</p>
        }
    </div>
}
import { useCallback, useEffect, useState } from "react";
import { TimeRecord } from "../domain/model/time-record";
import { TimeTrackingService } from "../domain/service/time-tracking-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";
import { Logger } from "../logging/logger";

export const useActiveTimeTracking = (onUpdate = () => { }) => {

  const user = useMustBeAuthenticated();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentTimeRecord, setCurrentTimeRecord] = useState<TimeRecord | undefined>(undefined);

  const getCurrentTimeRecord = useCallback(async () => {
    if (user) {
      const storedTimeRecord = await TimeTrackingService.getInstance().getCurrentRecord(user.uid);
      setCurrentTimeRecord(storedTimeRecord);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setError("");
      try {
        await getCurrentTimeRecord();
      } catch (error) {
        setError((error as Error).message);
      }
      setIsLoading(false);
    })()
  }, [user, getCurrentTimeRecord]);

  const stop = useCallback(async () => {
    setIsLoading(true);
    setError("");
    try {
      if (user) {
        Logger.debug(`Stopping current time record for user ${user.uid}`);
        await TimeTrackingService.getInstance().stopCurrentRecord(user.uid);
        onUpdate();
        setCurrentTimeRecord(undefined);
      } else {
        Logger.debug("No user, could not stop tracking time...");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [onUpdate, user]);

  const start = useCallback(async (description: string, projectId?: string) => {
    setIsLoading(true);
    setError("");
    try {
      if (user) {
        Logger.debug(`Starting new time record for user ${user.uid}`);
        const startedRecord = await TimeTrackingService.getInstance().startCurrentRecord(user.uid, description, projectId);
        onUpdate();
        setCurrentTimeRecord(startedRecord);
      } else {
        Logger.debug("No user, could not start tracking time...");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [onUpdate, user]);

  const updateCurrentRecord = useCallback(async (record: TimeRecord) => {
    setIsLoading(true);
    setError("");
    try {
      if (user) {
        Logger.debug(`Updating current time record.`);
        const updatedRecord = await TimeTrackingService.getInstance().updateCurrentRecord(user.uid, record);
        setCurrentTimeRecord(updatedRecord);
      } else {
        Logger.debug("No user, could not update current time record.");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user]);

  return {
    isLoading,
    currentTimeRecord,
    error,
    stop,
    start,
    updateCurrentRecord
  }
}
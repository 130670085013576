import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Row } from "../atoms/row/row"
import { PageContainer } from "../organisms/page-container/page-container"
import { VerifyEmailForm } from "../organisms/verify-email-form/verify-email-form";

export const VerifyEmailPage: React.FC = () => {

  return <PageContainer
    justifyContent="center"
    authentication={AuthenticationRule.MustNotBeAuthenticated}
    title="Verify email"
  >
    <Row justifyContent="center">
      <VerifyEmailForm />
    </Row>
  </PageContainer>

}
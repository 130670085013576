import { ReactNode } from "react";
import "./row.css";

interface RowProps {
    justifyContent?: "left" | "right" | "center" | "space-between" | "end" | "flex-end",
    flexWrap?: "nowrap" | "wrap",
    overflowX?: "auto" | "scroll" | "hidden" | "visible",
    overflowY?: "auto" | "scroll" | "hidden" | "visible",
    alignItems?: "center" | "flex-start" | "flex-end"
    children?: ReactNode,
    margin?: "top" | "bottom" | "none" | "left" | "top-and-bottom",
    className?: string,
    padding?: "top" | "bottom" | "left" | "none" | "right" | "around",
    expandMargin?: boolean,
    gap?: number
}

export const Row: React.FC<RowProps> = ({
    children,
    justifyContent = "left",
    flexWrap = "nowrap",
    overflowX = "auto",
    overflowY = "auto",
    alignItems = "center",
    margin = "none",
    padding = "none",
    className = "",
    expandMargin = false,
    gap = 1
}) => {

    let innerClassName = `row row-margin-${margin} ${className} row-padding-${padding} ${expandMargin ? "row-expand-margin" : ""}`;

    return <div className={innerClassName} style={{
        justifyContent,
        flexWrap,
        overflowX,
        alignItems,
        overflowY,
        gap: `${gap}rem`
    }}>
        {children}
    </div>
}
import { useSubmitButton, useTextInput } from "morfhp"
import { validateDisplayName } from "../../../domain/validators/auth-validators";
import { SaveButton } from "../../molecules/buttons/save-button";
import { TextInputGroup } from "../../molecules/input/text-input-group"

interface ProfileDetailsFormProps {
    name: string | null | undefined,
    updateDisplayName: (displayName: string) => Promise<void>
}

export const ProfileDetailsForm: React.FC<ProfileDetailsFormProps> = ({
    name,
    updateDisplayName
}) => {

    const nameInput = useTextInput({
        isRequired: true,
        label: "Display name",
        Component: TextInputGroup,
        validator: validateDisplayName,
        emptyValueMessage: "Please enter a display name.",
        validateInitially: !!name,
        defaultValue: name as (string | undefined)
    });

    const submitButton = useSubmitButton({
        inputs: [ nameInput ],
        Component: SaveButton,
        onSubmit: () => updateDisplayName(nameInput.formValue.value!)
    });

    return <>
        {nameInput.jsx}
        {submitButton.jsx}
    </>
}
import { DateTime } from "luxon";
import { useAuthentication } from "../../../firebase/useAuthentication"
import { Title } from "../../atoms/text/title"

export const Greeting: React.FC = () => {

  const user = useAuthentication();

  const currentDate = DateTime.now();

  const greetingText = currentDate.hour < 12
    ? "Goodmorning"
    : currentDate.hour < 18
      ? "Good afternoon"
      : "Good evening";


  return <Title>
    {
    (user && user.displayName)
      ? `${greetingText}, ${user.displayName}!`
      : `${greetingText}!`
    }
  </Title>
}
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logger } from "../logging/logger";

export enum AuthenticationRule {
  MustBeAuthenticated,
  MustNotBeAuthenticated,
  Either
}


export const useAuthentication = (authenticated = AuthenticationRule.Either) => {

  const navigate = useNavigate();
  const [lastChange, setLastChange] = useState(DateTime.now());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const auth = useMemo(() => getAuth(), [lastChange]);
  const user = auth.currentUser;

  useEffect(() => {
    onAuthStateChanged(auth, () => {
      setLastChange(DateTime.now());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticated === AuthenticationRule.MustBeAuthenticated && !user) {
      Logger.debug("Should be authenticated, redirecting to login...");
      navigate("/login");
    } else if (authenticated === AuthenticationRule.MustNotBeAuthenticated && user && !user.emailVerified) {
      Logger.debug("Email is not verified yet, redirection to login...");
      navigate("/verify-email")
    } else if (authenticated === AuthenticationRule.MustNotBeAuthenticated && user) {
      Logger.debug("Should NOT be authenticated, redirecting to home...");
      navigate("/overview");
    } else if (authenticated === AuthenticationRule.MustBeAuthenticated && !user?.emailVerified) {
      Logger.debug("Email is not verified yet, redirection to login...");
      navigate("/verify-email")
    }
  }, [authenticated, user, navigate]);

  return user;
}

export const useMustBeAuthenticated = () => {
  return useAuthentication(AuthenticationRule.MustBeAuthenticated);
}

export const useMustNotBeAuthenticated = () => {
  return useAuthentication(AuthenticationRule.MustNotBeAuthenticated);
}

export const useLogout = () => {
  const auth = getAuth()
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await auth.signOut();
      navigate("/");
    })();
  }, [navigate, auth]);
}
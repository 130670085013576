import { useCustomInput, useSubmitButton, useTextInput } from "morfhp"
import { useState } from "react"
import { validateTimeRecordDescription } from "../../../domain/validators/time-record-validators"
import { Card } from "../../atoms/card/card"
import { ErrorMessage } from "../../atoms/error/error-message"
import { ProjectInput } from "../../atoms/input/project/project-input"
import { Title } from "../../atoms/text/title"
import { StartTrackingButton } from "../../organisms/buttons/time-tracking/start-tracking-button"
import { TextInputGroup } from "../input/text-input-group"

interface StartTrackingFormProps {
  onStart: (description: string, projectId?: string) => void | Promise<void>
}

export const StartTrackingForm: React.FC<StartTrackingFormProps> = ({
  onStart
}) => {

  const [error, setError] = useState("");

  const descriptionInput = useTextInput({
    isRequired: false,
    label: "Description",
    Component: TextInputGroup,
    validator: validateTimeRecordDescription,
    placeholder: "Enter a description"
  });

  const projectInput = useCustomInput<string | undefined>({
    isRequired: false,
    label: "Select project",
    defaultValue: undefined,
    Component: ProjectInput,
    validator: (projectId) => projectId,
  });

  const startButton = useSubmitButton({
    inputs: [descriptionInput, projectInput],
    Component: StartTrackingButton,
    onSubmit: onSubmitClicked
  });

  async function onSubmitClicked() {
    try {
      const description = descriptionInput.formValue.value || "";
      const projectId = projectInput.formValue.value;
      await onStart(description, projectId);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <Card>
    <Title>Start tracking your time!</Title>
    {!!error && <ErrorMessage message={error} />}
    {descriptionInput.jsx}
    {projectInput.jsx}
    {startButton.jsx}
  </Card>

}
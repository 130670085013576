import { useSubmitButton, useTextInput } from "morfhp"
import { useState } from "react"
import { Client } from "../../../domain/model/client"
import { validateClientName } from "../../../domain/validators/client-validator"
import { ErrorMessage } from "../../atoms/error/error-message"
import { TextInputGroup } from "../../molecules/input/text-input-group"
import { SaveButton } from "../../molecules/buttons/save-button"

interface ClientFormProps {
  client?: Client,
  onSubmit: (newClient: Client) => Promise<void>
}

export const ClientForm: React.FC<ClientFormProps> = ({
  client,
  onSubmit
}) => {

  const [error, setError] = useState("");

  const nameInput = useTextInput({
    isRequired: true,
    label: "Client",
    Component: TextInputGroup,
    validator: validateClientName,
    emptyValueMessage: "Please enter a client name",
    placeholder: "Monsters Inc.",
    validateInitially: !!client,
    defaultValue: client?.name || ""
  });

  const submitButton = useSubmitButton({
    inputs: [nameInput],
    Component: SaveButton,
    onSubmit: onClientSubmit
  });

  async function onClientSubmit() {
    try {
      const name = nameInput.formValue.value!;
      const newClient = client  
        ? new Client(client.id, name)
        : Client.create(name);
      await onSubmit(newClient);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <>
    {!!error && <ErrorMessage message={error} />}
    {nameInput.jsx}
    {submitButton.jsx}
  </>
}
import { useCustomInput, useSubmitButton, useTextInput } from "morfhp";
import { useState } from "react";
import { TodoItem } from "../../../../domain/model/todo-item";
import { validateTodoItemDescription } from "../../../../domain/validators/todo-item-validators";
import { ErrorMessage } from "../../../atoms/error/error-message";
import { ProjectInput } from "../../../atoms/input/project/project-input";
import { SaveButton } from "../../../molecules/buttons/save-button";
import { TextInputGroup } from "../../../molecules/input/text-input-group";

interface TodoItemFormProps {
  item?: TodoItem,
  onSubmit: (todoItem: TodoItem) => Promise<void>
}

export const TodoItemForm: React.FC<TodoItemFormProps> = ({
  item,
  onSubmit
}) => {

  const [error, setError] = useState("");

  const descriptionInput = useTextInput({
    isRequired: true,
    label: "Description",
    validator: validateTodoItemDescription,
    Component: TextInputGroup,
    defaultValue: item?.description || "",
    emptyValueMessage: "Please enter a description",
    validateInitially: !!item,
    isMultiline: true,
    rows: 3
  });

  const projectInput = useCustomInput<string | undefined>({
    isRequired: false,
    label: "Select project",
    defaultValue: item?.projectId,
    Component: ProjectInput,
    validator: (projectId) => projectId,
  })

  const submitButton = useSubmitButton({
    inputs: [descriptionInput, projectInput],
    Component: SaveButton,
    onSubmit: innerOnSubmit
  })

  async function innerOnSubmit() {
    try {

      const description = descriptionInput.formValue.value!;
      const projectId = projectInput.formValue.value;

      const todoItem = !!item
        ? new TodoItem(item.id, description, item.creationTimestamp, projectId)
        : TodoItem.create(description, projectId);

      await onSubmit(todoItem);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  return <>
    {!!error && <ErrorMessage message={error} />}
    {descriptionInput.jsx}
    {projectInput.jsx}
    {submitButton.jsx}
  </>
}
import { DateTime, Duration } from "luxon";
import { chainable } from "valivalue";
import { v4 as uuidv4 } from "uuid"
import { formatDurationAsString } from "../../helpers/duration-helper";

export type uuid = string

export class TimeRecord {

    constructor(
        readonly id: uuid,
        readonly description: string,
        readonly start: DateTime,
        readonly end?: DateTime,
        readonly projectId?: string
    ) {
        chainable(true)
            .strings.validateLengthEquals(id, 36, 'Time record ID')
            .strings.validateMaxLength(description, 128, 'Time record description')
            .timestamps.validateIsInPast(start, 'Time record start');

        if (end) {
            chainable(true).timestamps.validateIsInPast(end, 'Time record end');
        }
    }

    stop(): TimeRecord {
        return new TimeRecord(this.id, this.description, this.start, DateTime.now(), this.projectId);
    }

    getDuration() {
        return (this.end || DateTime.now()).diff(this.start);
    }

    getDurationAsString(includeSeconds = false): string {
        return formatDurationAsString(this.getDuration(), includeSeconds);
    }

    static start(description = "", projectId = ""): TimeRecord {
        return new TimeRecord(
            uuidv4(),
            description,
            DateTime.now(),
            undefined,
            projectId
        );
    }

    static create(
        description = "",
        start = DateTime.now().startOf("day"),
        end = DateTime.now(),
        projectId = ""
    ): TimeRecord {
        return new TimeRecord(
            uuidv4(),
            description,
            start,
            end,
            projectId
        );
    }

    static getTotalDuration(records: TimeRecord[]) {
        if (records.length === 0) {
            return Duration.fromMillis(0);
        }

        let duration = records[0].getDuration();

        for (let index = 1; index < records.length; index++) {
            duration = duration.plus(records[index].getDuration());
        }

        return duration;
    }
}
import { wrap } from "module";
import "./key-value.css";

interface KeyValueProps {
    property: string;
    value: string;
    wrapValues?: boolean
}

export const KeyValue: React.FC<KeyValueProps> = ({
    property,
    value,
    wrapValues = true
}) => {

    return <>
        <p className="keyvalue-key">{property}:</p>
        <p className="keyvalue-value" style={{
            whiteSpace: wrapValues ? "inherit" : "nowrap"
        }}>{value}</p>
    </>
}
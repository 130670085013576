import { Client } from "../../../../domain/model/client";
import { useModal } from "../../../../hooks/useModal";
import { AddButton } from "../../../molecules/buttons/add-button";
import { ClientForm } from "../../clients/client-form";

interface CreateClientButtonProps {
  saveClient: (client: Client) => Promise<void>,

}

export const CreateClientButton: React.FC<CreateClientButtonProps> = ({
  saveClient
}) => {

  const modal = useModal();

  const innerOnsubmit = async (client: Client) => {
    await saveClient(client);
    modal.close();
  }

  const onButtonClick = async () => {
    modal.show({
      title: "Create new client",
      children: <ClientForm onSubmit={innerOnsubmit} />
    })
  }

  return <AddButton
    onClick={onButtonClick}
    text="Create new client"
  />
}
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom";
import "./navigation-item.css";

interface NavigationItemProps {
  icon: IconDefinition,
  text: string,
  route: string
}

export const NavigationItem: React.FC<NavigationItemProps> = ({
  icon,
  text,
  route
}) => {

  return <li className="navigation-item">
    <Link to={route}>
      <FontAwesomeIcon icon={icon} />
      {text}
    </Link>
  </li>
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDropdown } from "../../../hooks/useDropdown";
import { Spinner } from "../spinner/spinner";
import { DropdownMenuEntry } from "./dropdown-menu";

interface DropDownMenuItemProps {
  entry: DropdownMenuEntry
}

export const DropdownMenuItem: React.FC<DropDownMenuItemProps> = ({
  entry
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const dropdown = useDropdown();

  const innerOnClick = async () => {
    setIsLoading(true);
    try {
      await entry.onClick();
    } catch (error) {
      // TODO show error to user
      console.error((error as Error).message);
    }
    setIsLoading(false);
    dropdown.close();
  }

  return <div className="dropdown-menu-item" onClick={innerOnClick}>
    {!!isLoading ? <Spinner /> : <FontAwesomeIcon icon={entry.icon} />}
    {entry.text}
  </div>

}
import { DocumentData, DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { Project } from "../model/project";

export const ProjectConverter = {
  toFirestore: (project: Project) => {
    return {
      id: project.id,
      name: project.name,
      color: project.color,
      clientId: project.clientId
    }
  },
  fromFirestore: (snapshot: DocumentSnapshot<DocumentData>, options: SnapshotOptions) => {
    const data = snapshot.data(options);

    if (!data) {
      throw new Error("Failed to load project data.");
    }

    const {
      id,
      name,
      color,
      clientId
    } = data;

    return new Project(id, name, color, clientId);
  }
}
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { PageContainer } from "../organisms/page-container/page-container"

export const ErrorPage: React.FC = () => {
  return <PageContainer
    authentication={AuthenticationRule.Either}
    title="Oops..."
  >
    <h1>Oops, this page was not found...</h1>
  </PageContainer>
}
import { ClientsPage } from "../components/pages/clients-page";
import { ErrorPage } from "../components/pages/error-page";
import { HomePage } from "../components/pages/home-page";
import { LoginPage } from "../components/pages/login-page";
import { LogoutPage } from "../components/pages/logout-page";
import { OverviewPage } from "../components/pages/overview-page";
import { ProfilePage } from "../components/pages/profile-page";
import { RegisterPage } from "../components/pages/register-page";
import { ResetPasswordPage } from "../components/pages/reset-password-page";
import { TimeTrackPage } from "../components/pages/time-track-page";
import { TodoPage } from "../components/pages/todo-page";
import { VerifyEmailPage } from "../components/pages/verify-email-page";

export const routes = [
  { path: "/", element: <HomePage />, errorElement: <ErrorPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/logout", element: <LogoutPage /> },
  { path: "/verify-email", element: <VerifyEmailPage /> },
  { path: "/reset-password", element: <ResetPasswordPage /> },
  { path: "/overview", element: <OverviewPage /> },
  { path: "/timetrack", element: <TimeTrackPage /> },
  { path: "/clients", element: <ClientsPage /> },
  { path: "/todo", element: <TodoPage /> },
  { path: "/profile", element: <ProfilePage /> }
]
import { DateTime, Duration } from "luxon";
import { formatDurationAsString } from "../../../../helpers/duration-helper";
import { useProjectManager } from "../../../../hooks/useProjectManager";
import { TimeTrackingHistoryManager } from "../../../../hooks/useTimeTrackingHistory"
import { Card } from "../../../atoms/card/card"
import { Row } from "../../../atoms/row/row";
import { Title } from "../../../atoms/text/title";
import { DurationBlock } from "./duration-block";

interface TimeDistributionCardProps {
  lastUpdate: DateTime,
  timeTrackingHistory: TimeTrackingHistoryManager
}

const NO_PROJECT_ID = "no-project-id-for-record";

export const TimeDistributionCard: React.FC<TimeDistributionCardProps> = ({
  lastUpdate,
  timeTrackingHistory
}) => {

  const projectsManager = useProjectManager();

  const distributions = (() => {
    if (!timeTrackingHistory.hasRecords) {
      return undefined;
    }

    const durationPerProject = new Map<string, Duration>();
    let totalDuration = Duration.fromMillis(0);

    for (const record of timeTrackingHistory.timeRecords?.records!) {
      totalDuration = totalDuration.plus(record.getDuration());
      const projectId = record.projectId || NO_PROJECT_ID;
      const newDuration = durationPerProject.has(projectId)
        ? durationPerProject.get(projectId)!.plus(record.getDuration())
        : record.getDuration();
      durationPerProject.set(projectId, newDuration);
    }

    const durations = [];

    for (const record of Array.from(durationPerProject.entries())) {
      durations.push({
        project: projectsManager.projects?.find(project => project.id === record[0]),
        duration: record[1],
        percentage: Math.round(record[1].toMillis() / totalDuration.toMillis() * 100)
      });
    }

    return { durations, totalDuration };
  })();

  const startDate = (() => {
    if (!timeTrackingHistory.hasRecords) {
      return undefined;
    }

    let earliest = timeTrackingHistory.timeRecords?.records[0].start;

    for (const record of timeTrackingHistory.timeRecords?.records!) {
      if (record.start.toMillis() < earliest!.toMillis()) {
        earliest = record.start;
      }
    }

    return earliest;
  })();

  const endDate = (() => {
    if (!timeTrackingHistory.hasRecords) {
      return undefined;
    }

    let latest = timeTrackingHistory.timeRecords?.records[0].end;

    for (const record of timeTrackingHistory.timeRecords?.records!) {
      if (record.end!.toMillis() > latest!.toMillis()) {
        latest = record.end;
      }
    }

    return latest;
  })();

  return <Card>
    {(!timeTrackingHistory.hasRecords || !distributions)
      ? <p>No time tracked for projects in last 7 days.</p>
      : <>
        <Title h={4}>Time distribution from {startDate?.toLocaleString(DateTime.DATETIME_SHORT)} to {endDate?.toLocaleString(DateTime.DATETIME_SHORT)} - {formatDurationAsString(distributions.totalDuration!)} tracked</Title>
        <Row gap={0} overflowX="visible" overflowY="visible">
          {distributions.durations.map((duration, index) => {
            return <DurationBlock key={index} duration={duration.duration} percentage={duration.percentage} project={duration.project} />
          })}
        </Row>
      </>
    }
  </Card>
}
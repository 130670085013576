import { faDiagramProject } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Client } from "../../../domain/model/client"
import { useClientProjectManager } from "../../../hooks/useClientProjectManager"
import { ErrorMessage } from "../../atoms/error/error-message"
import { Row } from "../../atoms/row/row"
import { Spacer } from "../../atoms/spacer/spacer"
import { CenteredSpinner } from "../../atoms/spinner/centered-spinner"
import { Title } from "../../atoms/text/title"
import { ProjectTable } from "./project-table"
import { ClientButtons } from "../../molecules/clients/ClientButtons"

import "./client-card.css";

interface ClientCardProps {
  client: Client,
  saveClient: (client: Client) => Promise<void>,
  deleteClient: (client: Client) => Promise<void>
}

export const ClientCard: React.FC<ClientCardProps> = ({
  client,
  saveClient,
  deleteClient
}) => {

  const projectManager = useClientProjectManager(client);

  return <div className="client-card-container">
    <Row className="client-card-title" justifyContent="space-between" margin="bottom">
      <Row>
        <FontAwesomeIcon icon={faDiagramProject} />
        <Title h={3} margin={false}>{client.name}</Title>
      </Row>
      <ClientButtons
        client={client}
        saveClient={saveClient}
        deleteClient={deleteClient}
        saveProject={projectManager.saveProject}
      />
    </Row>
    {
      projectManager.isLoading
        ? <CenteredSpinner />
        : !!projectManager.error
          ? <ErrorMessage message={projectManager.error} />
          : <ProjectTable
            client={client}
            projects={projectManager.projects}
            deleteProject={projectManager.deleteProject}
            onProjectUpdate={projectManager.saveProject}
          />
    }
    <Spacer />
  </div>
}
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { Client } from "../../../../domain/model/client";
import { useModal } from "../../../../hooks/useModal";
import { WarningButton } from "../../../molecules/buttons/warning-button";
import { ClientForm } from "../../../organisms/clients/client-form";

interface EditClientButtonProps {
  onClick: (client: Client) => Promise<void>,
  client: Client
}

export const EditClientButton: React.FC<EditClientButtonProps> = ({
  onClick,
  client
}) => {

  const modal = useModal();

  const innerOnClick = async (client: Client) => {
    await onClick(client);
    modal.close();
  }

  const onButtonClick = () => {
    modal.show({
      children: <ClientForm client={client} onSubmit={innerOnClick} />,
      title: "Edit client"
    })

  }

  return <WarningButton
    canSubmit={true}
    isLoading={false}
    onSubmit={onButtonClick}
    text="Edit client"
    icon={faPencil}
    collapsible={true}
  />
}
import { DateTime } from "luxon";
import { TimeRecord } from "../../../domain/model/time-record";
import { TimeTrackingHistoryManager } from "../../../hooks/useTimeTrackingHistory"
import { ErrorMessage } from "../../atoms/error/error-message";
import { LoadMoreRecords } from "./load-more-records";
import { TimeRecordsByDay } from "./time-records-by-day";

interface TimeTrackingHistoryProps {
  lastUpdate: DateTime,
  startTracking: (timeRecord: TimeRecord) => Promise<void>,
  timeTrackingHistory: TimeTrackingHistoryManager
}

export const TimeTrackingHistory: React.FC<TimeTrackingHistoryProps> = ({
  lastUpdate,
  startTracking,
  timeTrackingHistory
}) => {

  return <>
    {
      !!timeTrackingHistory.error
        ? <ErrorMessage message={timeTrackingHistory.error} />
        : <>
          <TimeRecordsByDay
            timeRecords={timeTrackingHistory.timeRecords?.records || []}
            startTracking={startTracking}
            deleteTimeRecord={timeTrackingHistory.deleteRecord}
            updateTimeRecord={timeTrackingHistory.updateRecord}
          />
          <LoadMoreRecords
            hasNewItems={timeTrackingHistory.hasNewItems}
            getNext={timeTrackingHistory.getNext}
          />
        </>
    }
  </>

}
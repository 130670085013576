import { DateTime } from "luxon"
import { useCustomInput, useSubmitButton, useTextInput } from "morfhp"
import { useEffect, useState } from "react"
import { TimeRecord } from "../../../domain/model/time-record"
import { validateTimeRecordDescription, validateTimeRecordStart } from "../../../domain/validators/time-record-validators"
import { Card } from "../../atoms/card/card"
import { ErrorMessage } from "../../atoms/error/error-message"
import { ProjectInput } from "../../atoms/input/project/project-input"
import { Row } from "../../atoms/row/row"
import { Title } from "../../atoms/text/title"
import { StartNewTrackingButton } from "../../organisms/buttons/time-tracking/start-new-tracking-button"
import { StopRecordButton } from "../../organisms/buttons/time-tracking/stop-record-button"
import { UpdateRecordButton } from "../../organisms/buttons/time-tracking/update-record-button"
import { TextInputGroup } from "../input/text-input-group"
import { TimestampInputGroup } from "../input/timestamp-input-group"

interface CurrentRecordCardProps {
  record: TimeRecord
  onUpdate: (record: TimeRecord) => void | Promise<void>,
  onStop: () => void | Promise<void>,
  onStartNew: () => void | Promise<void>
  isLoading: boolean
}

export const CurrentRecordCard: React.FC<CurrentRecordCardProps> = ({
  isLoading,
  record,
  onUpdate,
  onStop,
  onStartNew
}) => {

  const [duration, setDuration] = useState("");
  const [error, setError] = useState("");

  const descriptionInput = useTextInput({
    isRequired: false,
    label: "Description",
    Component: TextInputGroup,
    validator: validateTimeRecordDescription,
    defaultValue: record.description || ""
  });

  const startInput = useCustomInput<DateTime>({
    isRequired: true,
    label: "Start",
    defaultValue: record.start,
    Component: TimestampInputGroup,
    validator: validateTimeRecordStart,
    emptyValueMessage: "Please enter a valid start timestamp.",
    validateInitially: true
  });

  const projectInput = useCustomInput<string | undefined>({
    isRequired: false,
    label: "Select project",
    defaultValue: record.projectId,
    Component: ProjectInput,
    validator: (projectId) => projectId,
  })

  const updateButton = useSubmitButton({
    inputs: [startInput, descriptionInput],
    Component: UpdateRecordButton,
    onSubmit: onUpdateButtonClick
  });

  async function onUpdateButtonClick() {
    try {
      const newDescription = descriptionInput.formValue.value!;
      const newTimestamp = startInput.formValue.value!;
      const projectId = projectInput.formValue.value;

      const newRecord = new TimeRecord(record.id, newDescription, newTimestamp, undefined, projectId);
      await onUpdate(newRecord);
    } catch (error) {
      setError((error as Error).message);
    }
  }

  const updateDuration = () => {
    setDuration(record.getDurationAsString(true));
    setTimeout(updateDuration, 1000);
  }

  useEffect(() => {
    updateDuration();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Card>
    <Title>{duration}</Title>
    {!!error && <ErrorMessage message={error} />}
    {descriptionInput.jsx}
    {startInput.jsx}
    {projectInput.jsx}
    <Row justifyContent="space-between">
      {updateButton.jsx}
      <StopRecordButton
        canSubmit={!isLoading}
        isLoading={isLoading}
        onSubmit={onStop}
      />
      <StartNewTrackingButton
        canSubmit={!isLoading}
        isLoading={isLoading}
        onSubmit={onStartNew}
      />
    </Row>
  </Card>

}
import { TodoBoard } from "../../../../domain/model/todo-board"
import { Row } from "../../../atoms/row/row"
import { Spacer } from "../../../atoms/spacer/spacer"
import { Title } from "../../../atoms/text/title"
import { TodoColumnsSection } from "../column/todo-columns-section"
import { TodoManager } from "../../../../hooks/useTodoManager"
import { TodoBoardButtons } from "../../../molecules/todo/todo-board-buttons"

import "./todo-board-section.css";

interface TodoBoardSectionProps {
  board: TodoBoard,
  todoManager: TodoManager
}

export const TodoBoardSection: React.FC<TodoBoardSectionProps> = ({
  board,
  todoManager
}) => {

  return <>
    <Spacer />
    <Row margin="bottom" className="todo-board-title">
      <Title h={3} margin={false}>{board.title}</Title>
      <TodoBoardButtons board={board} todoManager={todoManager} />
    </Row>
    {!board.isHidden && <Row margin="bottom" expandMargin={!!board.columns.length} alignItems="flex-start">
      {
        !board.columns.length
          ? <p>No columns yet.</p>
          : <TodoColumnsSection
            columns={board.columns}
            todoManager={todoManager}
          />
      }
    </Row>
    }
  </>
}
import { faCookie } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react"
import { Card } from "../../atoms/card/card";
import { ErrorMessage } from "../../atoms/error/error-message";
import { Row } from "../../atoms/row/row";
import { SuccessButton } from "../../molecules/buttons/success-button"

import "./CookiePopup.css";

interface CookiePopupProps {
  acceptCookies: () => Promise<void>
}

export const CookiePopup: React.FC<CookiePopupProps> = ({
  acceptCookies
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const onAcceptClicked = async () => {
    setIsLoading(true);
    setError("");
    try {
      await acceptCookies()
    } catch (error) {
      setError("Something went wrong while trying to accept cookies. Please try again later.");
    }
    setIsLoading(false);
  }

  return <Card className="cookie-popup">
    {
      !!error && <Row>
        <ErrorMessage
          message={error}
        />
      </Row>
    }
    <Row>
      <p>
        This website only uses functional cookies and local storage to store information.
        Accepting this is required to make the website work.
      </p>
      <SuccessButton
        canSubmit={!isLoading}
        isLoading={isLoading}
        onSubmit={onAcceptClicked}
        text="Accept"
        icon={faCookie}
        loadingText="Accepting"
      />
    </Row>
  </Card>
}
import { doc, getDoc, setDoc } from "firebase/firestore";
import { initFirestore } from "../../firebase/firebase-firestore";
import { Logger } from "../../logging/logger";
import { Cache } from "../caching/cache";
import { TodoBoard } from "../model/todo-board";
import { TodoConverter } from "../serializers/todo-converter";

const TODO_DOCUMENT = "todos";

export class TodoService {

  private readonly db = initFirestore();
  private static instance: TodoService | undefined;
  private todoCache: Cache<TodoBoard[]> | undefined;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new TodoService();
    }
    return this.instance;
  }

  public async saveTodos(userId: string, boards: TodoBoard[]) {
    const docRef = doc(this.db, userId, TODO_DOCUMENT).withConverter(TodoConverter);
    await setDoc(docRef, boards);
    Logger.debug("Caching todo boards.");
    if (this.todoCache) {
      this.todoCache.values = boards;
    } else {
      this.todoCache = new Cache(boards);
    }
    return this.getTodos(userId);
  }

  public async getTodos(userId: string) {
    if (this.todoCache && !this.todoCache.isExpired()) {
      if (this.todoCache.values.length > 0) {
        Logger.debug("Todo boards are chached, returning cached todo boards.");
        return this.todoCache.values;
      }
    }
    const docRef = doc(this.db, userId, TODO_DOCUMENT).withConverter(TodoConverter);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const todoBoards: TodoBoard[] = docSnap.data();
      Logger.debug("Caching todo boards.");
      if (this.todoCache) {
        this.todoCache.values = todoBoards;
      } else {
        this.todoCache = new Cache(todoBoards);
      }
      return todoBoards;
    }

    else return [TodoBoard.create("Personal board")];
  }
}
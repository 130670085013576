import { useCallback, useEffect, useState } from "react";
import { Project } from "../domain/model/project";
import { ProjectService } from "../domain/service/project-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";
import { Logger } from "../logging/logger";
import { useClientsManager } from "./useClientsManager";

export const useProjectManager = () => {

  const clientManager = useClientsManager();
  const user = useMustBeAuthenticated();
  const [projects, setProjects] = useState<Project[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const getProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      if (user) {
        Logger.debug(`Getting all projects for user '${user.uid}'.`)
        const projects = await ProjectService.getInstance().getAllProjects(user.uid);
        setProjects(projects);
      } else {
        Logger.debug("No user, could not get all projects.");
      }
    } catch (error) {
      setError((error as Error).message);
    }
    setIsLoading(false);
  }, [user]);

  const getClientForProject = useCallback((project: Project) => {
    return clientManager.clients.find(client => project.clientId === client.id);
  }, [clientManager.clients]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return {
    isLoading: isLoading || clientManager.isLoading,
    error,
    projects,
    getClientForProject
  }

}
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "../../../hooks/useModal";
import { Card } from "../../atoms/card/card";
import { Row } from "../../atoms/row/row";
import { Spacer } from "../../atoms/spacer/spacer";
import "./modal-container.css";

export const ModalContainer: React.FC = () => {

  const modal = useModal();

  return modal.isVisible && !!modal.children
    ?
    <div id="modal-container">
      <Spacer />
      <Row justifyContent="center">
        <Card>
          <h4
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <span>{modal.title}</span>
            <FontAwesomeIcon
              icon={faXmark}
              style={{ cursor: "pointer" }}
              onClick={modal.close}
            />
          </h4>
          {modal.children}
        </Card>
      </ Row>
    </div>

    : null
}
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { useClientsManager } from "../../hooks/useClientsManager"
import { Row } from "../atoms/row/row"
import { Title } from "../atoms/text/title"
import { CreateClientButton } from "../organisms/buttons/client/create-client-button"
import { ClientCard } from "../organisms/clients/client-card"
import { PageContainer } from "../organisms/page-container/page-container"

export const ClientsPage = () => {

  const clientsService = useClientsManager();

  return <PageContainer
    authentication={AuthenticationRule.MustBeAuthenticated}
    title="Clients & Projects"
  >
    <Row margin="bottom">
      <Title margin={false}>Clients</Title>
      <CreateClientButton saveClient={clientsService.saveClient} />
    </Row>
    {!clientsService.clients.length
      ? <p>No clients yet.</p>
      : clientsService.clients.map(client => <ClientCard
        key={client.id}
        client={client}
        saveClient={clientsService.saveClient}
        deleteClient={clientsService.deleteClient}
      />)
    }
  </PageContainer>
}
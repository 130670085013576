import { TimeRecord } from "../../../domain/model/time-record"
import { useModal } from "../../../hooks/useModal"
import { Table } from "../../atoms/tables/table";
import { ConfirmationDialog } from "../../organisms/dialogs/confirmation-dialog";
import { TimeRecordForm } from "./time-record-form";
import { TimeRecordRow } from "./time-record-row";

interface TimeRecordTableProps {
  timeRecords: TimeRecord[],
  startTracking: (record: TimeRecord) => Promise<void>,
  updateTimeRecord: (record: TimeRecord) => Promise<void>,
  deleteTimeRecord: (record: TimeRecord) => Promise<void>
}

export const TimeRecordTable: React.FC<TimeRecordTableProps> = ({
  timeRecords,
  startTracking,
  updateTimeRecord,
  deleteTimeRecord
}) => {

  const modal = useModal();

  const reversedTimeRecords = [...timeRecords].reverse();

  const onDeleteRecordClicked = (record: TimeRecord) => {
    modal.show({
      title: "Delete time record?",
      children: <ConfirmationDialog
        text={[
          "Are you sure you want to delete this time record?",
          "This action can not be undone."
        ]}
        onCancel={modal.close}
        onConfirm={async () => {
          await deleteTimeRecord(record);
          modal.close();
        }}
      />
    })
  }

  const onUpdateRecord = async (record: TimeRecord) => {
    await updateTimeRecord(record);
    modal.close();
  }

  const onUpdateRecordClicked = (record: TimeRecord) => {
    modal.show({
      title: "Update time record",
      children: <TimeRecordForm record={record} updateTimeRecord={onUpdateRecord} />
    });
  }

  const headings = [
    "Description",
    "Project",
    "Start",
    "End",
    "Duration",
    "",
    "Actions"
  ];

  return <Table headings={headings}>
    {reversedTimeRecords.map(record => <TimeRecordRow
      key={record.id}
      record={record}
      recordsByDay={timeRecords}
      startTracking={startTracking}
      deleteTimeRecord={onDeleteRecordClicked}
      updateTimeRecord={onUpdateRecordClicked}
    />)}
  </Table>
}
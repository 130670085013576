import { useEffect, useRef } from "react";
import { useDropdown } from "../../../hooks/useDropdown"

import "./dropdown-container.css";

export const DropdownContainer: React.FC = () => {

  const dropdown = useDropdown();
  const ref = useRef(null);

  const left = dropdown.isVisible && dropdown.parent && dropdown.parent.current ? (dropdown.parent.current as unknown as HTMLElement).getBoundingClientRect().left : 0;
  const width = dropdown.isVisible && dropdown.parent && dropdown.parent.current ? (dropdown.parent.current as unknown as HTMLElement).getBoundingClientRect().width : 0;
  const top = dropdown.isVisible && dropdown.parent && dropdown.parent.current ? (dropdown.parent.current as unknown as HTMLElement).getBoundingClientRect().top : 0;

  useEffect(() => {
    document.querySelector("main")!.addEventListener("scroll", dropdown.close);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current) {
      (ref.current as unknown as HTMLElement).addEventListener("mouseleave", dropdown.close)
    }
  }, [ref, dropdown.close])

  return (dropdown.isVisible && dropdown.children)
    ? <div
      className="dropdown-container"
      ref={ref}
      style={{
        top: `${top}px`,
        width: `${width}px`,
        left: `${left}px`
      }}
    >
      {dropdown.children}
    </div>
    : null;
}
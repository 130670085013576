import { KeyValue } from "./key-value";
import "./key-value-list.css";

interface KeyValueItem {
    property: string;
    value: string;
}

interface KeyValueListProps {
    keyValues: KeyValueItem[],
    wrapValues?: boolean
}

export const KeyValueList: React.FC<KeyValueListProps> = ({
    keyValues,
    wrapValues
}) => {
    return <div className="key-value-list">
        {keyValues.map(keyValue => <KeyValue key={keyValue.property} property={keyValue.property} value={keyValue.value} wrapValues={wrapValues}/>)}
    </div>
}
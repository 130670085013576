import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Row } from "../atoms/row/row"
import { PageContainer } from "../organisms/page-container/page-container"
import { RegisterForm } from "../organisms/register-form/register-form"

export const RegisterPage: React.FC = () => {
  return <PageContainer
    justifyContent="center"
    authentication={AuthenticationRule.MustNotBeAuthenticated}
    title="Register"
  >
    <Row justifyContent="center">
      <RegisterForm />
    </Row>
  </PageContainer>
}
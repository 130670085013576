import { faStopwatch } from "@fortawesome/free-solid-svg-icons"
import { LightButton } from "../../../molecules/buttons/light-button"

interface StartNewTrackingButtonProps {
  isLoading: boolean,
  text?: string,
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean
}

export const StartNewTrackingButton: React.FC<StartNewTrackingButtonProps> = ({
  text = "Start new",
  isLoading,
  canSubmit,
  onSubmit
}) => {

  return <LightButton
    icon={faStopwatch}
    canSubmit={canSubmit}
    isLoading={isLoading}
    onSubmit={onSubmit}
    text={text}
  />
}
import { Duration } from "luxon";

export const formatDurationAsString = (duration: Duration, includeSeconds = false) => {
  const [hours, minutes, seconds] = duration.toFormat("h m s").split(" ").map(num => parseInt(num, 10));

  const includeHours = hours > 0;
  const includeMinutes = minutes > 0;

  let result = "";

  if (includeHours) {
    result = `${hours} ${hours === 1 ? " hour" : " hours"}`;
  }

  if (includeHours && includeMinutes) {
    result += ", ";
  }

  if (includeMinutes) {
    result += `${minutes} ${minutes === 1 ? " minute" : " minutes"}`;
  }

  const shouldIncludeSeconds = (!includeHours && !includeMinutes) || includeSeconds;

  if (includeMinutes && shouldIncludeSeconds) {
    result += ", ";
  }

  if (shouldIncludeSeconds) {
    result += `${seconds} ${seconds === 1 ? " second" : " seconds"}`;
  }

  return result;
}
import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Row } from "../atoms/row/row"
import { Greeting } from "../organisms/greeting/greeting"
import { PageContainer } from "../organisms/page-container/page-container"
import { LastWeekTimingCard } from "../organisms/stats/last-week-time/last-week-timing-card"


export const OverviewPage: React.FC = () => {

  return <PageContainer
    authentication={AuthenticationRule.MustBeAuthenticated}
    title="Overview"
  >
    <Greeting />
    <Row>
      <LastWeekTimingCard />
    </Row>
  </PageContainer>
}
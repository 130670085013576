import { AuthenticationRule } from "../../firebase/useAuthentication"
import { Row } from "../atoms/row/row"
import { LoginForm } from "../organisms/login-form/login-form"
import { PageContainer } from "../organisms/page-container/page-container"

export const LoginPage = () => {
    return <PageContainer
        justifyContent="center"
        authentication={AuthenticationRule.MustNotBeAuthenticated}
        title="Log in"
    >
        <Row justifyContent="center">
            <LoginForm />
        </Row>
    </PageContainer>
}
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { TimeRecord } from "../domain/model/time-record";
import { TimeRecords } from "../domain/model/time-records";
import { TimeTrackingService } from "../domain/service/time-tracking-service";
import { useMustBeAuthenticated } from "../firebase/useAuthentication";

export interface TimeTrackingHistoryManager {
  isLoading: boolean;
  error: string;
  timeRecords: TimeRecords | undefined;
  deleteRecord: (record: TimeRecord) => Promise<void>;
  getNext: () => Promise<boolean>;
  hasNewItems: boolean;
  updateRecord: (record: TimeRecord) => Promise<void>;
  hasRecords: boolean;
}

export const useTimeTrackingHistory = (lastUpdate: DateTime): TimeTrackingHistoryManager => {

  const user = useMustBeAuthenticated();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [timeRecords, setTimeRecords] = useState<TimeRecords | undefined>(undefined);
  const [hasNewItems, setHasNewItems] = useState(true);

  const getTimeRecordsForLast7Days = useCallback(async () => {
    if (user) {
      return await TimeTrackingService.getInstance().getRecordsForPastDays(user.uid);
    }
  }, [user]);

  const getNext = useCallback(async () => {
    if (user && timeRecords) {
      const updatedTimeRecords = await timeRecords.getNext();
      const hasNewItems = updatedTimeRecords.records.length > timeRecords.records.length;
      setTimeRecords(updatedTimeRecords);
      setHasNewItems(hasNewItems);
    }
    return false;
  }, [user, timeRecords]);

  const deleteRecord = useCallback(async (record: TimeRecord) => {
    if (user) {
      TimeTrackingService.getInstance().deleteRecord(user.uid, record);
      setTimeRecords(await getTimeRecordsForLast7Days());
      setHasNewItems(true);
    }
  }, [getTimeRecordsForLast7Days, user]);

  const updateRecord = useCallback(async (record: TimeRecord) => {
    if (user) {
      TimeTrackingService.getInstance().updateRecord(user.uid, record);
      setTimeRecords(await getTimeRecordsForLast7Days());
      setHasNewItems(true);
    }
  }, [getTimeRecordsForLast7Days, user]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const timeRecords = await getTimeRecordsForLast7Days();
        setTimeRecords(timeRecords);
      } catch (error) {
        setError((error as Error).message);
      }
      setIsLoading(false);
    })();
  }, [getTimeRecordsForLast7Days, user, lastUpdate]);

  return {
    isLoading,
    error,
    timeRecords,
    deleteRecord,
    getNext,
    hasNewItems,
    updateRecord,
    hasRecords: !!timeRecords && !!timeRecords.records && timeRecords.records.length > 0
  };
}
import { deleteDoc, doc, getDoc, setDoc, collection } from "firebase/firestore";
import { initFirestore } from "../../firebase/firebase-firestore";
import { TimeRecord } from "../model/time-record";
import { TimeRecords } from "../model/time-records";
import { TimeRecordsByDay } from "../model/time-records-by-day";
import { TimeRecordConverter } from "../serializers/time-record-serializer";

const TIME_RECORDS_COLLECTION = "time-tracking-records/records";
const CURRENT_TIME_RECORD = "current-time-record";

export class TimeTrackingService {

  private readonly db = initFirestore();
  private static instance: TimeTrackingService | undefined;

  public static getInstance() {
    if (!this.instance) {
      this.instance = new TimeTrackingService();
    }
    return this.instance;
  }

  public async getCurrentRecord(userId: string) {
    const docRef = doc(this.db, userId, CURRENT_TIME_RECORD).withConverter(TimeRecordConverter);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    }
    return undefined;
  }

  public async deleteCurrentRecord(userId: string) {
    await deleteDoc(doc(this.db, userId, CURRENT_TIME_RECORD));
  }

  public async startCurrentRecord(userId: string, description = "", projectId = "") {
    await this.stopCurrentRecord(userId);
    const timeRecord = TimeRecord.start(description, projectId);
    const docRef = doc(this.db, userId, CURRENT_TIME_RECORD).withConverter(TimeRecordConverter);
    await setDoc(docRef, timeRecord);
    return timeRecord;
  }

  public async stopCurrentRecord(userId: string) {
    const currentTimeRecord = await this.getCurrentRecord(userId);
    if (currentTimeRecord) {
      const stoppedRecord = currentTimeRecord.stop();
      await this.addTimeRecord(userId, stoppedRecord);
      await this.deleteCurrentRecord(userId);
    }
  }

  public async updateCurrentRecord(userId: string, newTimeRecord: TimeRecord) {
    const docRef = doc(this.db, userId, CURRENT_TIME_RECORD).withConverter(TimeRecordConverter);
    await setDoc(docRef, newTimeRecord);
    return newTimeRecord;
  }

  public async addTimeRecord(userId: string, record: TimeRecord) {
    const docRef = doc(this.db, userId, TIME_RECORDS_COLLECTION, record.id).withConverter(TimeRecordConverter);
    await setDoc(docRef, record);
  }

  public async updateRecord(userId: string, record: TimeRecord) {
    const docRef = doc(this.db, userId, TIME_RECORDS_COLLECTION, record.id).withConverter(TimeRecordConverter);
    await setDoc(docRef, record);
  }

  public async deleteRecord(userId: string, record: TimeRecord) {
    await deleteDoc(doc(this.db, userId, TIME_RECORDS_COLLECTION, record.id));
  }

  public async getRecordsForPastDays(userId: string, days = 7): Promise<TimeRecords> {
    const collectionRef = collection(this.db, userId, TIME_RECORDS_COLLECTION).withConverter(TimeRecordConverter);
    return TimeRecordsByDay.lastDays(collectionRef, days);
  }
}
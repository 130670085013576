import { faStop } from "@fortawesome/free-solid-svg-icons"
import { WarningButton } from "../../../molecules/buttons/warning-button"

interface StopRecordButtonProps {
  onSubmit: () => void | Promise<void>,
  canSubmit: boolean,
  isLoading: boolean,
  text?: string
}

export const StopRecordButton: React.FC<StopRecordButtonProps> = ({
  onSubmit,
  canSubmit,
  isLoading,
  text = "Stop"
}) => {
  return <WarningButton
    onSubmit={onSubmit}
    icon={faStop}
    text={text}
    canSubmit={canSubmit}
    isLoading={isLoading}
  />
}